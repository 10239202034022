export default {
  "public.targetGroup": "Targeting",
  "public.adCorrection": "Bewijs",
  "public.adBudget": "Aankoop",
  "public.sponsored": "Gesponsord",
  "public.swipe": "Swipe",
  "public.uniquePerImage": "Indien uniek per gekozen afbeelding, scroll naar beneden om tekst te wijzigen",
  "public.gender&age": "Geslacht & Leeftijd",
  "public.emailExample": "example@email.com",
  "public.startDate": "Begindatum",
  "public.endDate": "Einddatum",
  "public.topMedia": "Top media",
  "public.social": "Sociaal",
  "public.display": "Weergave",
  "public.webTv": "Web-TV",
  "public.video": "Video",
  "public.digitalScreen": "Digitaal scherm (DOOH)",
  "public.devices": "Apparaten",
  "public.media": "Media",
  "public.url": "Url",
  "public.clicks": "Kliks",
  "public.createLink": "Link creëren",
  "public.generateReport": "Genereer rapport",
  "public.showFullReport": "Toon volledig rapport",
  "public.impressions": "Impressies",
  "public.reach": "Bereik",
  "public.reachPerPerson": " ganger/person",
  "public.ctr": "CTR",
  "public.ctrReach": "CTR Bereik",
  "public.ctrImpressions": "CTR Impressies",
  "public.channels": "Kanalen",
  "public.mobile": "Mobiel",
  "public.desktop": "Desktop",
  "public.fbMobile": "Facebook mobiel",
  "public.fbDesktop": "Facebook desktop",
  "public.mediaBudget": "Mediabudget",
  "public.geoPlaces": "Geo targeting locaties",
  "public.reportRecipient": "Ontvangers rapport",
  "public.campaignManagers": "Campagnebeheerders",
  "public.newAdImages": "Geef afbeeldingen op voor uw advertentie",
  "public.currentAdImages": "Huidige afbeeldingen op voor uw advertentie",
  "public.adLandingPage": "Landingspagina",
  "public.report": "Rapport",
  "public.orderProofStep": "Selecteer tekst",
  "public.orderTargetingStep": "Selecteer Geo Targeting Locaties & Publiek",
  "public.orderLocationStep": "Selecteer Geo Targeting Locaties",
  "public.orderBudgetStep": "Selecteer budget, planning & dashboard ontvangers",
  "public.adTextAboveImage": "Advertentie beschrijving boven beeld",
  "public.adTitleBelowImage": "Titel onder afbeelding",
  "public.descBelowImage": "Beschrijving onder de afbeelding",
  "public.otherImages": "Andere beschikbare afbeeldingen om in uw advertentie te gebruiken",
  "public.costCenter": "Kostencentrum",
  "public.chooseMediaBudget": "Kies een mediabudget",
  "public.addLinkedInJobPost": "Kies mediabudget voor vacature op LinkedIn",
  "public.linkedInAmount": "LinkedIn bedrag",
  "public.noLinkedJobPost": "Geen LinkedIn vacature",
  "public.purchase": "Inkoop",
  "public.purchaser": "Inkoper",
  "public.purchased": "Gekocht",
  "public.perDayCost": "{value} SEK per dag",
  "public.sendNewLogin": "Stuur nieuwe aanmelding",
  "public.extendAd": "Advertentie verlengen",
  "public.adTotal": "Totaal:",
  "public.targetGroupControlledAds": "Gerichte vertoningen",
  "public.age": "Leeftijd",
  "public.gender": "Geslacht",
  "public.man": "Man",
  "public.men": "Mannen",
  "public.woman": "Vrouw",
  "public.women": "Vrouwen",
  "public.reference": "Referentie",
  "public.like": "Vind ik leuk",
  "public.comment": "Commentaar",
  "public.share": "Delen",
  "public.comments": "{value} opmerkingen",
  "public.shares": "{value} aandelen",
  "public.minMaxAge": "Min {min}. Max {max}",
  "public.openNewWindow": "Open in nieuw venster",
  "public.order": "Plaats bestelling",
  "public.next": "Volgende",
  "public.previous": "Vorige",
  "public.adPeriod": "Periode",
  "public.companyName": "Bedrijfsnaam",
  "public.uploadImage": "Afbeelding uploaden",
  "public.asap": "Begin zo snel mogelijk",
  "public.totalValue": "Totaal {value}",
  "public.lastShowDate": "Standaard einddatum gedetecteerd",
  "public.createNewAdMultiEmail":
    "Geef eventuele extra e-mailadressen op waarop u het Campagnedashboard wilt ontvangen. Er kunnen meerdere e-mailadressen worden toegevoegd.",
  "public.hintCampaignManagers":
    "Geef aan welke extra e-mailadressen u wilt machtigen om de campagne uit te verlengen. Er kunnen meerdere e-mailadressen worden toegevoegd.",
  "public.addNewEmail": "Voeg e-mailadres toe, er kunnen meerdere e-mailadressen worden opgegeven",
  "public.shortTextRecomended":
    "Houd het kort - wij adviseren {recomendedLength} tekens of minder. Het is momenteel {value} tekens.",
  "public.linkedInReporting": "Wij melden geen automatisch gerichte advertenties van LinkedIn",
  "public.vatPrices": "Prijzen {vatType} {vatSuffix}",
  "public.vatPricesFull": "Prijzen {vatType} {vatRate}% {vatSuffix}",
  "public.validatingLocations": "Geolocaties valideren",
  "public.tags": "Voeg meerdere tags toe, typ tekst en druk op enter.",
  "public.tenantMediaLocale": "Voeg locale toe door tekst te typen en op enter te drukken of selecteer uit opties.",
  "public.tenantMediaTags": "Voeg meerdere tags toe, typ tekst en druk op enter of selecteer uit opties.",
  "public.tenantMediaCategories": "Voeg tekst toe en druk op enter of selecteer uit opties.",
  "public.newAdConfirmation": "U ontvangt een bevestiging van uw bestelling op het opgegeven e-mailadres.",
  "public.adCreated": "De advertentie is gecreëerd en u kunt dit venster nu sluiten.",
  "public.minBudget": " Het moet ten minste {waarde} indruk per dag zijn om de bestelling te plaatsen.",
  "public.dragAndDropImages": "Bepaal de volgorde en welke afbeeldingen worden getoond door 'Drag'n'Drop'",
  "public.addTargetGroupLocation": "Voeg geolocatie doelen toe door te zoeken naar plaatsen of adressen.",
  "public.more": "...Meer",
  "public.readMore": "...Lees meer",
  "public.fileDrop": "Klik om bestanden te kiezen of sleep ze hierheen om te uploaden",
  "public.orders": "Bestellingen",
  "public.exclude": "Excl.",
  "public.include": "Incl.",
  "public.vatSuffix": "BTW",
  "public.vat": "{vatType} {vatRate}% {vatSuffix}",
  "public.vatNoRate": "{vatType} {vatSuffix}",
  "public.loading": "Laden",
  "public.newLoginSent": "Nieuwe aanmelding verzonden",
  "public.errorLogin": "Fout, neem contact op",
  "public.requestNewLogin": "Nieuwe aanmelding per e-mail aangevraagd",
  "public.noImageSelected": "Geen afbeelding geselecteerd",
  "public.multiImageCategories": "Selecteer een of meer beeldcategorieën",
  "public.infoSpecialAdCategory":
    "Demografische instellingen vergrendeld, in overeenstemming met het speciale advertentiebeleid van Meta (Facebook & Instagram)",
  "public.edit": "Bewerken",
  "public.success": "Succes!",
  "public.orderPlaced":"Bedankt voor uw bestelling!",
  "public.hint.image": "Geaccepteerde bestandstypen: JPG/PNG/BMP",
  "public.hint.font": "Geaccepteerde bestandstypen: .ttf/.woff",
  "public.youTube": "YouTube",
  "public.date": "Datum",
  "public.unauthorized": "Onbevoegd: Toegang geweigerd.",
  "tooltip.prefilledField":
    "Als u het standaard vooraf ingevulde veld wilt overschrijven: Referentie (Referentienummer, kostenplaats of persoon)",
  "title.prefilledField": "Vooraf ingevulde kostenplaats",

  "imageTextOption.same": "Zelfde voor alle",
  "imageTextOption.unique": "Uniek per afbeelding",

  "landingPageSetting.uneditable": "Onbewerkbaar",
  "landingPageSetting.same": "Zelfde voor alle",
  "landingPageSetting.unique": "Uniek",

  "common.noCategory": "Ingen kategori",
  "common.all": "Alle",
  "common.inactive": "Inactief",
  
  "common.timeInterval": "Gebruik aangepast interval",
  "common.showDayAndTime": "Dag en tijd interval weergeven",
  "common.customDateAndTime": "Datum en tijd",
  "common.crawlingSpindleSpeed": "Spil snelheid",
  "common.customDaySpeed": "Dagen van de week",
  "common.customHourSpeed": "Uren",
  "common.customMinuteSpeed": "Minuten",
  "common.cronExpression": "Gebruik CRON-expressie",
  "common.cronStatement": "OF u kunt de onderstaande CRON-expressie gebruiken",
  
  "confirm.useInactiveDataSourceOnCampaign": "Weet u zeker dat u de inactieve gegevensbron ({dataSource}) wilt instellen op {campaign}?",

  "title.selectedDay":"Geselecteerde dag",

  "calltoaction.NO_BUTTON": "Geen knop",
  "calltoaction.BUY_NOW": "Nu kopen",
  "calltoaction.APPLY_NOW": "Nu aanvragen",
  "calltoaction.BOOK_TRAVEL": "Boek reis",
  "calltoaction.WATCH_MORE": "Meer bekijken",
  "calltoaction.CONTACT_US": "Neem contact met ons op",
  "calltoaction.DOWNLOAD": "Download",
  "calltoaction.GET_OFFER": "Offerte aanvragen",
  "calltoaction.GET_QUOTE": "Prijs aanvragen",
  "calltoaction.SIGN_UP": "Aanmelden",
  "calltoaction.SUBSCRIBE": "Inschrijven",
  "calltoaction.INTERESTED": "Geïnteresseerd",
  "calltoaction.GET_SHOWTIMES": "Ontvang showtijden",
  "calltoaction.REQUEST_TIME": "Tijd aanvragen",
  "calltoaction.LEARN_MORE": "Meer informatie",
  "calltoaction.CALL_NOW": "Bel nu",

  "error.required": "Dit veld is verplicht.",
  "error.cpm": "Cpm moet een getal zijn en tussen 65 en 900 liggen.",
  "error.number": "Dit veld moet een getal zijn.",
  "error.chooseOneGender": "* Tenminste één geslacht",
  "error.dailyBudgetLow": "Het mediabudget is te laag, verhoog het mediabudget hierboven of verkort het campagneschema",
  "error.email": "Geef een geldige e-mail",
  "error.generic": "Er is iets fout gegaan, probeer het opnieuw",
  "error.imageUploadError": "Mislukt om afbeeldingen op te slaan",
  "error.oneTargetGroupArea": "Ten minste één geolocatie",
  "error.locationOverlap": "Geolocaties mogen elkaar niet overlappen",
  "error.noFacebookPage": "Voer de url van uw Facebook-pagina in om door te gaan",
  "error.invalidFacebookPage": "De Facebook-pagina is niet correct, controleer uw spelling",
  "error.badLink": "De landingspagina is niet correct, controleer uw spelling",
  "error.noLandingPage": "U moet uw landingspagina invoeren",
  "error.noGenderChoosen": "Geen geslacht geselecteerd",
  "error.noTargetGroupArea": "Er moet ten minste één geolocatie worden opgegeven",
  "error.locationNoLocationOverlap": "Er moet ten minste één geolocatie worden opgegeven en ze mogen elkaar niet overlappen",
  "error.closeEndDate": "Einddatum is slechts 48h verwijderd van startdatum, einddatum moet 48h in de toekomst liggen",
  "error.orderCouldNotComplete": "De bestelling is mislukt",
  "error.fileTobig": "Het bestand is te groot max 50mb, kies een ander bestand",
  "error.notImageFile": "Het bestand moet een afbeelding zijn, kies een ander bestand",
  "error.couldNotExtendProspect": "Kon advertentie niet verlengen, probeer later opnieuw",
  "error.couldNotOrderAd": "Kon advertentie niet bestellen",
  "error.channelDistribution": "Kanaal distributie moet in totaal 100% zijn",

  "error.errorMessageMoreMinute": "Minuten moeten groter zijn dan 0",
  "error.errorMessageLessMinute": "Minuten mogen maximaal 59 zijn",
  "error.errorMessageMoreHours": "Uren moeten groter zijn dan 0",
  "error.errorMessageLessHours": "Uren mogen maximaal 23 zijn",

  "public.error.proofRequired": "Ten minste één afbeelding is vereist",
  "public.error.proofDisplayRequired": "Er zijn minimaal {value} afbeeldingen vereist",
  "public.error.hasEmptyPhoneNumbers": "Het telefoonnummerveld mag niet leeg zijn",
  "public.error.file-invalid-type": "Ongeldig bestandstype",
  "public.error.file-too-large": "Het bestand is te groot max 50mb, kies een ander bestand",
  "public.error.too-many-files": "Te veel bestanden, kies er een",
  "public.error.pageNotFound": "Pagina niet gevonden!",
  "public.error.pageNotFoundDescription": "Voer een geldige url in, of neem contact op met support",
  "public.error.pageNetworkError": "Interne Server Fout",
  "public.error.pageNetworkErrorDescription": "Probeer het later nog eens, of neem contact op met support",
  "public.error.landingPages": "Alle bestemmingspagina's moeten een geldige URL zijn.",

  "billingType.unspecified": "Niet gespecificeerd",
  "billingType.monthly": "Maandelijks vooraf",
  "billingType.monthlyArrears": "Maandelijks met betalingsachterstand",
  "billingType.quarterly": "Kwartaal vooraf",
  "billingType.semiYearly": "Halfjaarlijks vooraf",
  "billingType.yearly": "Jaarlijks vooraf",
  "billingType.onDemand": "Bij bestelling in de webshop",
  "billingType.external": "Extern",

  "title.apiChannels": "Toegestane API-kanalen",
  "title.useCustomStaticCreatives": "Gebruik aangepaste statische advertenties",
  "title.linkedInPageId": "LinkedIn-referentie-ID",
  "title.linkedInAdAccountId": "LinkedIn-advertentieaccount-ID",
}

import { DefaultTheme } from "styled-components"
import { createTheme } from "@mui/material/styles"

export const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: window.tenant ? window.tenant.color : "#1e90ff" },
    secondary: { main: window.tenant ? window.tenant.color : "#292f36" },
  },
})

export const defaultTheme: DefaultTheme = {
  primaryColor: window.tenant ? window.tenant.color : "#1e90ff",
  primaryTransparent: "rgba(30, 144, 255, 0.19)",
  accent: "#263954",
  muted: "hsla(210, 17%, 69%, 1)",
  lightGray: "#f1f2f6",
  darkGray: "#292f36",
  light: "#f8f9fb",
  lighter: "#fafafd",
  grayTransparent: "#a3b0bd33",
  white: "#fff",
  blue: "#1e90ff",
  lightBlue: "#f9fbff",
  red: "#ff4757",
  orange: "#ff9a47",
  green: "#2ed573",
  dark: "#191c1f",
  darkTransparent: "#323232",
  facebook: "#3397f0",
  grey: "#c7c7c7",
  spacing: "16px",
  spacingLarge: "48px",
  spacingSmall: "8px",
  containerPadding: "2em 0",
  fontSize: "15px",
  fontSizeSmall: "13px",
  borderRadius: "3px",
  boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
  primaryBorder: "3px solid #1e90ff",
  lightBorder: "2px solid #f1f2f6",
  mediumScreen: "768px",
  largeScreen: "1440px",
  transitionDuration: "150ms",
}

export default {
  "public.targetGroup": "Målretting",
  "public.adCorrection": "Korrektur",
  "public.adBudget": "Kjøp",
  "public.sponsored": "Sponset",
  "public.swipe": "Sveip",
  "public.uniquePerImage": "Hvis unikt per bilde er valgt, rull ned for å endre tekst",
  "public.gender&age": "Kjønn og alder",
  "public.emailExample": "example@email.com",
  "public.startDate": "Startdato",
  "public.endDate": "Sluttdato",
  "public.topMedia": "Topp media",
  "public.social": "Sosial",
  "public.display": "Visning",
  "public.webTv": "Nett-tv",
  "public.video": "Video",
  "public.digitalScreen": "Digital skjerm (DOOH)",
  "public.devices": "Enheter",
  "public.media": "Media",
  "public.url": "Nettadresse",
  "public.clicks": "Klikk",
  "public.createLink": "Opprett lenke",
  "public.generateReport": "Generér rapport",
  "public.showFullReport": "Vis hele rapporten",
  "public.impressions": "Inntrykk",
  "public.reach": "Rekkevidde",
  "public.reachPerPerson": " ganger/person",
  "public.ctr": "CTR",
  "public.ctrReach": "CTR-rekkevidde",
  "public.ctrImpressions": "CTR-inntrykk",
  "public.channels": "Kanaler",
  "public.mobile": "Mobil",
  "public.desktop": "Skrivebord",
  "public.fbMobile": "Facebook-mobil",
  "public.fbDesktop": "Facebook-skrivebord",
  "public.mediaBudget": "Mediebudsjett",
  "public.geoPlaces": "Geografisk målretting mot steder",
  "public.reportRecipient": "Rapportmottakere",
  "public.campaignManagers": "Kampanjeledere",
  "public.newAdImages": "Legg til bilder for annonsen din",
  "public.currentAdImages": "Gjeldende bilder for annonsen din",
  "public.adLandingPage": "Landingsside",
  "public.report": "Rapport",
  "public.orderProofStep": "Velg tekst",
  "public.orderTargetingStep": "Velg geografisk målrettingssteder og målgruppe",
  "public.orderLocationStep": "Velg geografisk målrettingssteder",

  "public.orderBudgetStep": "Velg budsjett, tidsplan og rapportmottakere",
  "public.adTextAboveImage": "Annonsebeskrivelse ovenfor bildet",
  "public.adTitleBelowImage": "Tittel under bildet",
  "public.descBelowImage": "Beskrivelse nedenfor bildet",
  "public.otherImages": "Andre tilgjengelige bilder for bruk i annonsen din",
  "public.costCenter": "Kostnadssenter",
  "public.chooseMediaBudget": "Velg mediebudsjett",
  "public.addLinkedInJobPost": "Velg mediebudsjett for stillingspost på LinkedIn",
  "public.linkedInAmount": "LinkedIn beløp",
  "public.noLinkedJobPost": "Ingen LinkedIn jobbannonse",
  "public.purchase": "Kjøp",
  "public.purchaser": "Kjøper",
  "public.purchased": "Kjøpt",
  "public.perDayCost": "{value} NOK per dag",
  "public.sendNewLogin": "Send ny pålogging",
  "public.extendAd": "Forleng annonse",
  "public.adTotal": "Totalt:",
  "public.targetGroupControlledAds": " Målrettede inntrykk",
  "public.age": "Alder",
  "public.gender": "Kjønn",
  "public.man": "Mann",
  "public.men": "Menn",
  "public.woman": "Kvinne",
  "public.women": "Kvinner",
  "public.reference": "Referanse",
  "public.like": "Liker",
  "public.comment": "Kommentar",
  "public.share": "Dele",
  "public.comments": "{value} kommentarer",
  "public.shares": "{value} delinger",
  "public.minMaxAge": "Min {min}. Maks {max}",
  "public.openNewWindow": "Åpne i nytt vindu",
  "public.order": "Legg inn bestilling",
  "public.next": "Neste",
  "public.previous": "Forrige",
  "public.adPeriod": "Periode",
  "public.companyName": "Bedriftsnavn",
  "public.uploadImage": "Last opp bilde",
  "public.asap": "Start så snart som mulig",
  "public.totalValue": "Totalt {value}",
  "public.lastShowDate": "Oppdaget sluttdato som standard",
  "public.createNewAdMultiEmail":
    "Vennligst spesifiser til hvilke ytterligere e-postadresser du ønsker å motta kampanjedashbordet. Flere e-postadresser kan legges til.",
  "public.hintCampaignManagers":
    "Vennligst spesifiser eventuelle ekstra e-postadresser du ønsker å bli autorisert til for å utvide kampanjen. Flere e-postadresser kan legges til.",
  "public.addNewEmail": "Legg til e-postadresse, flere e-postadresser kan oppgis",
  "public.shortTextRecomended":
    "Hold det kort - vi anbefaler {recomendedLength} tegn eller mindre. Det er for øyeblikket {value} tegn.",
  "public.linkedInReporting": "Vi rapporterer ikke automatiske målrettingsannonser fra LinkedIn",
  "public.vatPrices": "Priser {vatType} {vatSuffix}",
  "public.vatPricesFull": "Priser {vatType} {vatRate}% {vatSuffix}",
  "public.validatingLocations": "Validerer geografiske plasser",
  "public.tags": "Legg til flere tagger, skriv inn tekst og trykk enter.",
  "public.tenantMediaLocale": "Legg til lokalitet ved å skrive inn tekst og trykk enter eller velg fra alternativer.",
  "public.tenantMediaTags": "Legg til flere tagger, skriv inn tekst og trykk enter eller velg fra alternativer.",
  "public.tenantMediaCategories": "Legg til tekst og trykk enter eller velg fra alternativer.",
  "public.newAdConfirmation": "Du vil motta en bekreftelse på bestillingen din til den oppgitte e-postadressen.",
  "public.adCreated": "Annonsen har blitt opprettet, og du kan nå lukke dette vinduet.",
  "public.minBudget": "Det må være minst {value} visning per dag for å bestille.",
  "public.dragAndDropImages": "Bestem rekkefølgen og hvilke bilder som skal vises ved å dra og slippe",
  "public.addTargetGroupLocation": "Legg til geografiske lokaliseringsmål ved å søke etter steder eller adresser..",
  "public.more": "...Mer",
  "public.readMore": "...Les mer",
  "public.fileDrop": "Klikk for å velge filer eller dra dem hit for å laste opp",
  "public.orders": "Ordrene",
  "public.exclude": "utelukke",
  "public.include": "inkludert",
  "public.vatSuffix": "mva",
  "public.vat": "{vatType} {vatRate}% {vatSuffix}",
  "public.vatNoRate": "{vatType} {vatSuffix}",
  "public.loading": "Laster",
  "public.newLoginSent": "Ny pålogging sendt",
  "public.errorLogin": "Noe gikk galt, kontakt",
  "public.requestNewLogin": "Be om ny pålogging via e-post",
  "public.noImageSelected": "Ingen bilde valgt",
  "public.multiImageCategories": "Velg én eller flere bildekategorier",
  "public.infoSpecialAdCategory":
    "Demografiske innstillinger er låst, i samsvar med Metas (Facebook & Instagram) special ad policy",
  "public.edit": "Redigere",
  "public.success": "Suksess!",
  "public.orderPlaced":"Takk for din bestilling!",
  "public.hint.image": "Godkjente filtyper: JPG/PNG/BMP",
  "public.hint.font": "Godkjente filtyper: .ttf/.woff",
  "public.youTube": "YouTube",
  "public.date": "Dato",
  "public.unauthorized": "Uautorisert: Tilgang nektet.",
  "tooltip.prefilledField":
    "Hvis du ønsker å overstyre standard forhåndsutfylt felt: Referanse (Referansenummer, kostnadssted eller person)",
  "title.prefilledField": "Forhåndsutfylt kostnadssted",
  "title.linkedInPageId": "LinkedIn Referanse-ID",
  "title.linkedInAdAccountId": "LinkedIn annonsekonto-ID",

  "imageTextOption.same": "Samme for alle",
  "imageTextOption.unique": "Unik pr. bilde",

  "landingPageSetting.uneditable": "Uredigerbar",
  "landingPageSetting.same": "Samme for alle",
  "landingPageSetting.unique": "Unik",

  "common.noCategory": "Ingen kategori",
  "common.all": "Alle",
  "common.inactive": "Inaktiv",
  "common.addValue": "Legg til {value}",
  "common.timeInterval": "Bruk Tilpasset Intervall",
  "common.showDayAndTime": "Dag og Tidsintervall",
  "common.customDateAndTime": "Dato Og Tid",
  "common.crawlingSpindleSpeed": "Spindelhastighet",
  "common.customDaySpeed": "Ukedager",
  "common.customHourSpeed": "Timer",
  "common.customMinuteSpeed": "Minutter",
  "common.cronExpression": "Bruk CRON-uttrykk",
  "common.cronStatement": "ELLER du kan bruke CRON-uttrykket nedenfor",

  
  "confirm.useInactiveDataSourceOnCampaign": "Er du sikker på at du vil sette den inaktive datakilden ({dataSource}) på {campaign}?",

  "title.selectedDay":"Valgt dag",

  "title.metaMedia": "Meta Media (Facebook & Instagram)",

  "documentTitle.resultReport": "Resultatrapport for {campaignName}",

  "calltoaction.NO_BUTTON": "Ingen knapp",
  "calltoaction.BUY_NOW": "Kjøp nå",
  "calltoaction.APPLY_NOW": "Søk nå",
  "calltoaction.BOOK_TRAVEL": "Book nå",
  "calltoaction.WATCH_MORE": "Se flere",
  "calltoaction.CONTACT_US": "Kontakt oss",
  "calltoaction.DOWNLOAD": "Last ned",
  "calltoaction.GET_OFFER": "Få tilbud",
  "calltoaction.GET_QUOTE": "Få pristilbud",
  "calltoaction.SIGN_UP": "Registrer deg",
  "calltoaction.SUBSCRIBE": "Abonner",
  "calltoaction.INTERESTED": "Interessert",
  "calltoaction.GET_SHOWTIMES": "Få visningstider",
  "calltoaction.REQUEST_TIME": "Be om tidspunkt",
  "calltoaction.LEARN_MORE": "Lær mer",
  "calltoaction.CALL_NOW": "Ring nå",

  "error.required": "Dette feltet er obligatorisk",
  "error.cpm": "Cpm må være et tall og i området 65 til 900",
  "error.number": "Dette feltet må være et tall",
  "error.chooseOneGender": "* Minst ett kjønn",
  "error.dailyBudgetLow": "Mediebudsjettet er for lavt, øk mediebudsjettet over eller kort ned kampanjeplanen.",
  "error.email": "Oppgi en gyldig e-postadresse",
  "error.generic": "Noe gikk galt, prøv igjen",
  "error.imageUploadError": "Kunne ikke lagre bilder",
  "error.oneTargetGroupArea": "Minst én geografisk plassering",
  "error.locationOverlap": "Geografiske plasseringer må ikke overlappe",
  "error.noFacebookPage": "Skriv inn nettadressen (url) til Facebook-siden din for å fortsette",
  "error.invalidFacebookPage": "Facebook-siden er ikke korrekt, sjekk stavemåten din",
  "error.badLink": "Landingssiden er ikke riktig, sjekk stavemåten din",
  "error.noLandingPage": "Du må gå inn på landingssiden din",
  "error.noGenderChoosen": "Intet kjønn valgt",
  "error.noTargetGroupArea": "Minst én geografisk plassering må oppgis",
  "error.locationNoLocationOverlap": "Minst én geografisk plassering må oppgis, og de kan ikke overlappe",
  "error.closeEndDate": "Sluttdatoen er kun 48h unna startdatoen, sluttdatoen må være 48h fram i tid",
  "error.orderCouldNotComplete": "Bestillingen ble ikke behandlet",
  "error.fileTobig": "Filen er for stor maks 50mb, velg en annen fil",
  "error.notImageFile": "Filen må være et bilde, velg en annen fil",
  "error.couldNotExtendProspect": "Kunne ikke forlenge annonsen, prøv igjen senere.",
  "error.couldNotOrderAd": `Kunne ikke bestille annonse`,

  "error.errorMessageMoreMinute": "Minutter må være større enn 0",
  "error.errorMessageLessMinute": "Minutter må maksimalt være 59",
  "error.errorMessageMoreHours": "Timer må være større enn 0",
  "error.errorMessageLessHours": "Timer må maksimalt være 23",

  "public.error.proofRequired": "Minst ett bilde kreves",
  "public.error.proofDisplayRequired": "Minst {value} bilder kreves",
  "public.error.hasEmptyPhoneNumbers": "Telefonnummerfeltet må ikke være tomt",
  "public.error.file-invalid-type": "Ugyldig filtype",
  "public.error.file-too-large": "Filen er for stor maks 50mb, velg en annen fil",
  "public.error.too-many-files": "For mange filer, velg én",
  "public.error.pageNotFound": "Side ikke funnet!",
  "public.error.pageNotFoundDescription": "Vennligst skriv inn en gyldig url, eller kontakt brukerstøtten.",
  "public.error.pageNetworkError": "Intern server feil",
  "public.error.pageNetworkErrorDescription": "Prøv igjen senere, eller kontakt brukerstøtten.",
  "public.error.landingPages": "Alle landingssider må være en gyldig nettadresse.",

  "billingType.unspecified": "Uspesifisert",
  "billingType.monthly": "Månedlig i forveien",
  "billingType.monthlyArrears": "Månedlig på etterskudd",
  "billingType.quarterly": "Kvartalsvis i forveien",
  "billingType.semiYearly": "Halvårlig på forhånd",
  "billingType.yearly": "Årlig på forhånd",
  "billingType.onDemand": "Ved bestilling i nettbutikken",
  "billingType.external": "Utvendig",

  "title.apiChannels": "Tillatte Api-kanaler",
  "title.useCustomStaticCreatives": "Bruk egendefinerte statiske reklamer",
}

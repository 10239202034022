export default {
  "public.targetGroup": "Målgrupp",
  "public.adCorrection": "Annonskorrektur",
  "public.adBudget": "Beställ",
  "public.sponsored": "Sponsrad",
  "public.uniquePerImage": "Om unik per objekt är vald, skrolla ner för att byta text",
  "public.uniqueNumberPerImage": "Om unik per objekt är vald, skrolla ner för att byta nummer",
  "public.swipe": "Svajpa",
  "public.gender&age": "Kön & ålder",
  "public.emailExample": "example@email.com",
  "public.startDate": "Startdatum",
  "public.endDate": "Slutdatum",
  "public.topMedia": "Top media",
  "public.social": "Social",
  "public.display": "Display",
  "public.webTv": "Webb-TV",
  "public.video": "Video",
  "public.digitalScreen": "Digital Skärm (DOOH)",
  "public.devices": "Enheter",
  "public.media": "Media",
  "public.url": "Url",
  "public.clicks": "klick",
  "public.createLink": "Skapa Länk",
  "public.generateReport": "Generera rapport",
  "public.showFullReport": "Visa hela rapporten",
  "public.impressions": "Visningar",
  "public.reach": "Räckvidd",
  "public.ctr": "Klickfrekvens",
  "public.ctrReach": "CTR Räckvidd",
  "public.ctrImpressions": "CTR Annonsvisningar",
  "public.channels": "Annonskanaler",
  "public.mobile": "Mobil",
  "public.desktop": "Desktop",
  "public.fbMobile": "Facebook mobil",
  "public.fbDesktop": "Facebook desktop",
  "public.mediaBudget": "Mediabudget",
  "public.geoPlaces": "Geografiska platser",
  "public.geo": "Geo",
  "public.reportRecipient": "Rapportmottagare",
  "public.emailReportRecipient": "Rapportmottagare för månadsrapportering (E-post)",
  "public.campaignManagers": "Kampanjföreståndare",
  "public.newAdImages": "Välj material till din annons",
  "public.currentAdImages": "Nuvarande material till din annons",
  "public.adLandingPage": "Landingssida",
  "public.report": "Rapport",
  "public.orderProofStep": "Välj annonstext",
  "public.orderTargetingStep": "Välj var din annons skall synas & för vem",
  "public.orderLocationStep": "Välj var din annons skall synas",
  "public.DisconnectTenant": "Är du säker på att du vill koppla från {tenantName} från Användare {userName}",

  "imageTextOption.same": "Samma för alla",
  "imageTextOption.unique": "Unik per objekt",

  "public.orderBudgetStep": "Välj budget, period & rapportmottagare",
  "public.adTextAboveImage": "Annonstext ovanför annons",
  "public.adTitleBelowImage": "Titel under annons",
  "public.descBelowImage": "Beskrivning under annons",
  "public.otherImages": "Övrigt material att använda i annonsen",
  "public.costCenter": "Kostnadsställe",
  "public.chooseMediaBudget": "Välj mediabudget",
  "public.addLinkedInJobPost": "Välj mediabudget för annonsering på LinkedIn",
  "public.linkedInAmount": "LinkedIn budget",
  "public.noLinkedJobPost": "Ingen annonsering på LinkedIn",
  "public.purchase": "Beställ",
  "public.purchaser": "Beställare",
  "public.purchased": "Beställd",
  "public.perDayCost": "{value} kr per dag",
  "public.sendNewLogin": "Skicka ny inlogging",
  "public.extendAd": "Förläng annonsen",
  "public.adTotal": "Totalt:",
  "public.targetGroupControlledAds": " Målgruppstyrda annonsvisningar",
  "public.age": "Ålder",
  "public.gender": "Kön",
  "public.man": "Man",
  "public.men": "Män",
  "public.unspecified": "Ospecificerad",
  "public.woman": "Kvinna",
  "public.women": "Kvinnor",
  "public.reference": "Referens",
  "public.like": "Gilla",
  "public.comment": "Kommentera",
  "public.share": "Dela",
  "public.comments": "{value} kommentarer",
  "public.shares": "{value} delningar",
  "public.minMaxAge": "Min {min}. Max {max}",
  "public.openNewWindow": "Öppna i nytt fönster",
  "public.order": "Beställ",
  "public.next": "Nästa",
  "public.previous": "Föregående",
  "public.adPeriod": "Annonsperiod",
  "public.companyName": "Företagsnamn",
  "public.uploadImage": "Ladda upp bild",
  "public.asap": "Starta så snart som möjligt",
  "public.totalValue": "Totalt {value}",
  "public.lastShowDate": "Sista visnings-/ansökningsdatum och tid är autoifyllt",
  "public.createNewAdMultiEmail": "Ansvarig persons e-post, om sådan finns, är autoifyllt. Flera e-postadresser kan anges",
  "public.hintCampaignManagers":
    "Lägg till e-postadresser som du vill ska ha rättighet att förlänga en kampanj. Flera e-postadresser kan anges",
  "public.addNewEmail": "Ange e-postadress, flera e-postadresser kan anges",
  "public.shortTextRecomended":
    "Håll det kort - vi rekommenderar {recomendedLength} tecken eller mindre. Just nu {value} tecken.",
  "public.linkedInReporting": "Vi rapporterar inte automatiskt målgruppsstyrda annonsvisningar från LinkedIn",
  "public.vatPrices": "Priser {vatType} {vatSuffix}",
  "public.vatPricesFull": "Priser {vatType} {vatRate}% {vatSuffix}",
  "public.validatingLocations": "Validerar målgruppsområden",
  "public.tags": "Ange text och tryck enter, flera taggar kan anges.",
  "public.tenantMediaLocale": "Lägg till locale genom att ange text och tryck enter.",
  "public.tenantMediaTags": "Ange text och tryck enter eller välj från listan, flera taggar kan anges.",
  "public.tenantMediaCategories": "Ange text och tryck enter eller välj från listan",
  "public.newAdConfirmation":
    "På den mejladress du angivit vid beställningen kommer du få mer information om din annons, så håll utkik i inkorgen.",
  "public.adCreated": `Din annons är skapad och kommer strax gå live.`,
  "public.minBudget": " Antalet visningar får som lägst vara {value} st per dag.",
  "public.dragAndDropImages": `Bestäm ordningen på bilderna samt vilka bilder som ska visas genom "Drag and Drop"`,
  "public.addTargetGroupLocation": "Lägg till målgruppsområden genom att söka på adress..",
  "public.more": "...Mer",
  "public.readMore": "...Läs mer",
  "public.fileDrop": "klicka för att välja filer eller dra de hit",
  "public.orders": "Beställningar",
  "public.exclude": "exkl.",
  "public.include": "inkl",
  "public.vatSuffix": "moms",
  "public.vat": "{vatType} {vatRate}% {vatSuffix}",
  "public.vatNoRate": "{vatType} {vatSuffix}",
  "public.loading": "Laddar",
  "public.newLoginSent": "Ny inloggning skickad",
  "public.errorLogin": "Någonting gick snett, kontakta",
  "public.adError": "Kan inte skapa annons för",
  "public.requestNewLogin": "Begär en ny inloggning via mail",
  "public.noImageSelected": "Ingen bild vald",
  "public.multiImageCategories": "Välj en eller flera bildkategorier",
  "public.infoSpecialAdCategory":
    "Demografiska inställningar låsta, i enlighet med Metas (Facebook & Instagram) special ad policy",
  "public.edit": "Redigera",
  "public.success": "framgång!",
  "public.orderPlaced": "Tack för din beställning!",
  "public.hint.image": "Godkända filtyper: JPG/PNG/BMP",
  "public.hint.font": "Godkända filtyper: .ttf/.woff",
  "public.youTube": "YouTube",
  "public.date": "Datum",
  "public.unauthorized": "Obehörig: Åtkomst nekas.",
  "public.permissiondenied": "Åtkomst nekad: Du har inte behörighet att se detta innehåll",
  "public.additionalInfo": "Övrig information",

  "public.recreateCampaignMessage": "Återskapa kampanj {campaignName} för klienten {clientName}",
  "public.confirmRecreateCampaign": "Återskapa {selectedChannel} kampanj {campaignName} för klienten {clientName}",

  "public.phoneNumberSettings": "Telefonnummerinställningar",
  "public.genderType": "Typ av kön",
  "public.reachPerPerson": " gånger/person",

  "public.uploadLogoSuccess": "Logotypen har laddats upp!",
  "public.uploadLogoFailed": "Uppladdning av logotypen misslyckades!",
  "public.errorMessage": "Ett fel uppstod",

  "public.later": "Vänligen kom tillbaka senare",
  "public.noFacebookAd": "Annonsen har inte skapats ännu",

  "calltoaction.NO_BUTTON": "Ingen knapp",
  "calltoaction.BUY_NOW": "Köp nu",
  "calltoaction.APPLY_NOW": "Ansök nu",
  "calltoaction.BOOK_TRAVEL": "Boka nu",
  "calltoaction.WATCH_MORE": "Se mer",
  "calltoaction.CONTACT_US": "Kontakta oss",
  "calltoaction.DOWNLOAD": "Ladda ned",
  "calltoaction.GET_OFFER": "Hämta erbjudande",
  "calltoaction.GET_QUOTE": "Beställ offert",
  "calltoaction.SIGN_UP": "Registrera dig",
  "calltoaction.SUBSCRIBE": "Prenumerera",
  "calltoaction.GET_SHOWTIMES": "Hämta visningstider",
  "calltoaction.REQUEST_TIME": "Boka tid",
  "calltoaction.LEARN_MORE": "Läs mer",
  "calltoaction.CALL_NOW": "Ring nu",

  "error.required": "Fältet får inte vara tomt",
  "error.requiredField": "Fältet {value} får inte vara tomt", //Just translated on se and en right now
  "error.requiredNumberField": "{value} och får inte vara tomt", //Just translated on se and en right now
  "error.cpm": "Cpm måste vara ett nummer mellan 65 och 900",
  "error.cpm.boundary.min": "Min måste vara mindre än max",
  "error.cpm.value": "Cpm måste vara ett nummer mellan {min} och {max}",
  "error.cpm.boundaries.mixed": "Både min och max måste vara satta",
  "error.number": "Fältet måste vara ett nummer",
  "error.chooseOneGender": "* Minst ett kön måste väljas",
  "error.dailyBudgetLow":
    "Er dagsbudget i kampanjen är för låg, öka mediabudgeten ovan eller minska antalet annonsdagar i kampanjen.",
  "error.email": "Ange en giltig e-post",
  "error.generic": "Något gick fel, försök igen",
  "error.imageUploadError": "Kunde inte spara bilderna",
  "error.oneTargetGroupArea": "Minst ett målgruppsområde måste väljas",
  "error.locationOverlap": "Geografiska målgruppsområden kan inte överlappa varandra, du måste ta bort ett",
  "error.locationAlreadyExists": "Den valda platsen har redan angetts",
  "error.noFacebookPage": "Ni måste skriva i er webbadress till er Facebooksida för att komma vidare",
  "error.invalidFacebookPage": "Facebook-sidan är inte korrekt angiven, kontrollera stavningen.",
  "error.badLink": "Länken är inte korrekt angiven, kontrollera stavningen.",
  "error.noLandingPage": "Ni måste skriva i er webbadress till er landingssida för att komma vidare",
  "error.noGenderChoosen": "Inget kön valt",
  "error.noTargetGroupArea": "Det finns inga målgruppsområden tillagda. Lägg till några genom fältet nedan",
  "error.orderCouldNotComplete": "Beställningen kan inte genomföras",
  "error.locationNoLocationOverlap":
    "Du måste välja minst ett geografiskt målgruppsområde och geografiska målgruppsområden får inte överlappa varandra",
  "error.notValidInterest": "Inget intresse matchar.",
  "error.closeEndDate": "Slutdatum är bara 48h ifrån startdatum, slutdatum måste minst vara 48h frammåt i tiden",
  "error.noCampaignsFound": "Inga kampanjer hittade.",
  "error.fileTobig": "Filen är för stor max 50mb, välj en annan fil",
  "error.notImageFile": "Filen måste vara en bild, välj en annan fil",
  "error.inValidEndDate": "Slutdatumet måste vara senare än startdatumet",
  "error.minAge": "Minimiåldern måste vara högre än eller lika med 18 år",
  "error.maxAge": "Maximal ålder måste vara mindre än eller lika med 100",
  "error.ageValidation": "Max Age måste vara större än eller lika med Min Age",
  "error.pricingLevel": "Minst en prisnivå krävs",
  "error.pricingLevelMaxValue": "Värdet måste vara mindre än eller lika med 200000",
  "error.alphaNumerical": "Ange endast numeriska värden i prisnivåerna",
  "error.templateTags": "Ange minst ett värde i fältet för malltagg",
  "error.maxNum": "Det maximala inmatningstalet måste vara mindre än eller lika med 100",
  "error.minNum": "Minsta indatanummer måste vara större än eller lika med 1",
  "error.uniqueValue": "Fältet måste innehålla unika värden",
  "error.zero": "Värdet måste vara över noll",
  "error.noFloat": "Fältet accepterade inte decimalvärdet",

  "public.error.proofRequired": "Du måste välja minst en bild för att fortsätta",
  "public.error.proofDisplayRequired": "Du måste välja minst {value} bilder för att fortsätta",
  "public.error.hasEmptyPhoneNumbers": "Fältet för telefonnummer får inte vara tomt",
  "public.error.file-invalid-type": "Otillåten filtyp, välj en annan fil",
  "public.error.file-too-large": "Filen är för stor max 50mb, välj en annan fil",
  "public.error.too-many-files": "För många bilder, välj en",
  "public.error.pageNotFound": "Sidan kunde inte hittas!",
  "public.error.pageNotFoundDescription": "Skriv en giltig sökväg eller kontakta support.",
  "public.error.pageNetworkError": "Internt serverfel",
  "public.error.pageNetworkErrorDescription": "Var god försök igen senare eller kontakta support.",
  "public.error.landingPages": "Alla landningssidor måste vara en giltig webbadress.",

  "common.save": "Spara",
  "common.close": "Stäng",
  "common.choose": "Välj",
  "common.pause": "Pausa",
  "common.unpause": "Ta bort pausning",
  "common.addValue": "Lägg till {value}",
  "common.editValue": "Redigera {value}",
  "common.createValue": "Skapa {value}",
  "common.generateValue": "Generera {value}",
  "common.copyValue": "Kopiera {value}",
  "common.changeValue": "Byt {value}",
  "common.removeValue": "Radera {value}",
  "common.pauseValue": "Pausa {value}",
  "common.unpauseValue": "Ta bort pausning {value}",
  "common.deactivateValue": "Inaktivera {value}",
  "common.selectValue": "Välj en {value}",
  "common.premadeValue": "Förhandsgjord {value}",
  "common.types": "Typ",
  "common.mailContact": "Kontakta {email}",
  "common.name": "Namn",
  "common.category": "Kategori",
  "common.adAccountStrategy": "Reklamkonto strategi",
  "common.adAccountId": "Reklamkonto Id",
  "common.fallbackPageId": "Facebook default sida id",
  "common.dsaBeneficiary": "Marknadsförd part (DSA)",
  "common.dsaPayor": "Betalare (DSA)",
  "common.forceFeedPlacement": "Tvinga placering i Feeden för samtliga videoformat",
  "common.cpm": "Cpm",
  "common.cpm.settings.min": "Min: ≥{value}",
  "common.cpm.settings.max": "Max: ≤{value}",
  "common.defaultMediaImages": "Standard mediebilder",
  "common.group": "Grupp",
  "common.reseller": "Återförsäljare",
  "common.fbPage": "Facebooksida",
  "common.externalId": "Externt Id",
  "common.addMetadata": "Lägg till metadata",
  "common.dataSource": "Datakälla",
  "common.template": "Annonsmall",
  "common.ordererName": "Beställnings namn",
  "common.ordererEmail": "Beställnings mail",
  "common.deactivate": "Deaktivera",
  "common.activate": "Aktivera",
  "common.actions": "Funktioner",
  "common.totalAmount": "Totalt belopp",
  "common.conversionRate": "Konverteringsgrad",
  "common.thirtyTotalConversionRate": "30dagar {thirty}% (Totalt {total}%)",
  "common.cancelOrder": "Avbryt order",
  "common.cancelReason": "Avbryt anledning",
  "common.cancelCampaign": "Nej, avbryt",
  "common.confirmRecreateCampaign": "Ja, återskapa",
  "common.removeClient": "Ta bort klient",
  "common.connectTenant": "Anslut partner",
  "common.disconnectTenant": "Koppla bort partner",
  "common.addTenant": "Lägg till partner",
  "common.partnerImages": "Partner Logos",
  "common.validationMessage": "Vänligen ange prisnivåer",
  "common.acceptNumericNumbers": "Prisnivåer får endast innehålla numeriska värden",
  "common.sendDraftMails": "Skicka draft mail",
  "common.updatedBy": "Uppdaterad av",

  "common.created": "Skapad",
  "common.prospect": "Prospekt",
  "common.prospectSource": "Prospektkälla",
  "common.prospectSources": "Prospektkällor",
  "common.noEditValue": "{value} ej redigerbar",
  "common.noRemoveValue": "{value} ej borttagbar",
  "common.prolong": "Förläng kampanj",
  "common.recreate": "Återskapa",
  "common.recreateCampaign": "Återskapa kampanj",
  "common.recreateWarning": "Varning, är du säker på att du vill:",
  "common.recreatFacebook": "Facebook",
  "common.isStartDate": "Lägg till budgetstartdatum",
  "common.rename": "Byt namn",
  "common.configure": "Konfiguera",
  "common.used": "Använd",
  "common.objectAmount": "Antal objekt",
  "common.modified": "Ändrad",
  "common.failure": "Fel",
  "common.dataChange": "Data ändring",
  "common.spidered": "Spindlad",
  "common.dynamic": "Dynamisk/Spindlad",
  "common.static": "Statisk",
  "common.imageLibrary": "Bildbank",
  "common.budget": "Budget",
  "common.order": "Beställning",
  "common.item": "Artikel",

  "common.remaining": "Återstående",
  "common.delete": "Radera",
  "common.end": "Avsluta",
  "common.all": "Alla",
  "common.noCategory": "Ingen kategori",
  "common.create": "Skapa",
  "common.clearAll": "Rensa alla",
  "common.price": "Pris",
  "common.email": "E-post",
  "common.returnToDashboard": "Återvänd till kundöversikt",
  "common.mobileNumber": "Mobilnummer",
  "common.send": "Skicka",
  "common.title": "Titel",
  "common.password": "Lösenord",
  "common.login": "Logga in",
  "common.logout": "Logga ut",
  "common.client": "Kund",
  "common.active": "Aktiva",
  "common.opened": "Öppnad",
  "common.completion": "Slutförande",
  "common.remove": "Ta bort",
  "common.filters": "Filter",
  "common.createdBy": "Skapad av",
  "common.field": "Fält",
  "common.type": "Typ",
  "common.patterns": "Mönster",
  "common.request": "Webbbegäran",
  "common.description": "Beskrivning",
  "common.phoneNumber": "Telefonnummer",
  "common.themeColor": "Temafärg",
  "common.clientLogo": "Kundlogga",
  "common.yes": "Ja",
  "common.no": "Nej",
  "common.api": "API",

  "common.january": "Januari",
  "common.february": "Februari",
  "common.march": "Mars",
  "common.april": "April",
  "common.may": "Maj",
  "common.june": "Juni",
  "common.july": "Juli",
  "common.august": "Augusti",
  "common.september": "September",
  "common.october": "Oktober",
  "common.november": "November",
  "common.december": "December",

  "common.search": "Sök...",
  "common.inactive": "Inaktiva",
  "common.showInactive": "Visa inaktiva",
  "common.showSandboxed": "Visa Sanboxed",
  "common.copied": "Kopierad!",
  "common.copyCollectionValue": "Kopiera {length} av {collection} id:n",
  "common.campaign": "Kampanj",
  "common.tenant": "Partner",
  "common.targeting": "Annonsinriktning",
  "common.budgetPeriod": "budgetperiod",
  "common.currencyCode": "Valutakod",
  "common.maxItemsInOrder": "Max antal produkter i order",
  "common.languageCode": " Språkkod",
  "common.locale&currency": " Språkkod & Valutakod",
  "common.value": "värde",
  "common.averageValue": "Genomsnittlig {value}",
  "common.copy": "Kopiera",
  "common.role": "Roll",
  "common.status": "Status",
  "common.facebook": "Facebook",
  "common.bidTheatre": "Bidtheatre",
  "common.linkedIn": "LinkedIn",
  "common.clearChannel": "ClearChannel",
  "common.youTube": "YouTube (DV360)",
  "common.youTubeGoogleAds": "YouTube (GoogleAds)",
  "common.removeItem": "Ta bort objekt",
  "common.configureTemplateData": "Konfigurera malldata",

  "common.textFormat": "Textformat",
  "common.rootTextFormat": "rot textformat",
  "common.itemTextFormat": "objekt textformat",
  "common.saveAndContinue": "Spara och fortsätt",
  "common.exitWithoutSaving": "Stäng utan att spara",
  "common.resetPassword": "Återställ lösenord",

  "common.id": "Id",
  "common.path": "Sökväg",
  "common.format": "Format",
  "common.duration": "Varaktighet",
  "common.items": "Föremål",
  "common.groupings": "Grupperingar",
  "common.previous": "Föregående",
  "common.previousStep": "Föregående steg",
  "common.next": "Nästa",
  "common.page": "Sida",
  "common.pageSize": "Sidstorlek",
  "common.crawlingSpeed": "Spindelhastighet",
  "common.customDateAndTime": "Datum och Tid",
  "common.crawlingSpindleSpeed": "Spindelhastighet",
  "common.customDaySpeed": "Veckans dagar",
  "common.customHourSpeed": "Timmar",
  "common.customMinuteSpeed": "Minuter",
  "common.cronExpression": "Använd CRON-uttryck",
  "common.cronStatement": "ELLER så kan du använda CRON-uttrycket nedan",
  "common.timeInterval": "Använd anpassat intervall",
  "common.showDayAndTime": "Dag och tidsintervall",
  "title.selectedDay": "Vald dag",
  "title.pdfUpload":"{value} pdf uppladdning",
  "title.uploadPdf": "Ladda upp ny pdf",
  "title.weeklyPdf": "Veckovis pdf",
  "title.uploadPdfFile": "Ladda upp ny pdf",
  "title.selectPdf": "välj pdf",

  "common.displayName": "Display namn",
  "common.groupName": "Grupp namn",
  "common.groupId": "Grupp id",
  "common.facebookPageId": "Facebook-sida id",
  "common.billingType": "Faktureringssätt",
  "common.massSelection": "Massurval",
  "common.massZipCode": "Lägg till postnummer",
  "common.zipCode": "Postnummer",
  "common.none": "Ingen",
  "common.orderTemplateItem": "Beställningsartikel",
  "common.orderTemplate": "Ingen beställningsmall tillgänglig. Skapa en ny ordermall",
  "common.slug": "Slug",
  "common.access": "Åtkomst",

  "common.logoTitle": "Välj  {value} för Partner",

  "confirm.removeValue": "Är du säker på du vill ta bort {value}?",
  "confirm.removeAutomations": "Är du säker på du vill radera de här automationsinställningarna?",
  "confirm.removeRecipientGroup": "Är du säker på du vill radera den här mottagargruppen?",
  "confirm.removeScheduledReport": "Är du säker på du vill ta bort den här schemalagda rapporten?",
  "confirm.remove": "Är du säker på du vill ta bort?",
  "confirm.removeProvider": "Är du säker på du vill radera leverantör {providerIndex}?",
  "confirm.openNewWindow": "Är du säker på du vill öppna i nytt fönster?",
  "confirm.removeObjectName": "Är du säker på du vill radera den här {value}?",
  "confirm.archive": "Är du säker på du vill arkivera?",
  "confirm.deactivate": "Är du säker på du vill deaktivera {value}?",
  "confirm.activate": "Är du säker på du vill aktivera {value}?",
  "confirm.removePrompt": "Är du säker på att du vill ta bort {value}?",
  "confirm.copyValue": "Är du säker på du vill kopiera {value}?",
  "confirm.exitWithoutSaving": "Dina ändringar kommer bli förlorade. Är du säker på du vill lämna?",
  "confirm.resetPassword": "Är du säker på att du vill återställa lösenordet?",
  "confirm.cancel": "Är du säker på att du vill avbryta order {value}?",
  "confirm.unpause": "Är du säker på du vill ta bort pausning?",
  "confirm.generateKeys":
    "När en nyckel redan har genererats och en ny nyckel genereras, kommer den gamla att försvinna och sluta fungera. Är du säker på att du vill generera en ny nyckel?",
  "confirm.insightSampleData": "Är du säker på att du vill skapa insights provdata?",
  "confirm.continueWithSavedHandover":
    "Vill du fortsätta med den sparade överlämningen (klicka Jag vill fortsätta) eller börja om från början (klicka Jag vill börja om)?",
  "confirm.continue": "Jag vill fortsätta",
  "confirm.restart": "Jag vill börja om",
  "confirm.startedHandover": "Påbörjad överlämning",
  "confirm.useInactiveDataSourceOnCampaign":
    "Är du säker på att du vill sätta den inaktiva datakällan ({dataSource}) på {campaign}?",

  "error.lowValue": "För lågt värde, min {value}",
  "error.highValue": "För högt värde, max {value}",
  "error.noFieldsFound": "Inga matchade fält",
  "error.pricePackage.small": "Small måste vara mindre än medium",
  "error.pricePackage.medium": "Medium måste vara större än small och mindre än large",
  "error.pricePackage.large": "Large måste vara större än small/medium och mindre än maxbelopp",
  "error.pricePackage.max": "Maxbelopp måste vara större än large",
  "error.automations": "Om automationen innehåller en mottagare får den inte ha tomt namn eller ogiltig e-post",
  "error.recipientGroups":
    "Mottagargrupper måste innehålla minst en mottagare per grupp, mottagaren får inte ha tomt namn eller ogiltig e-post",
  "error.dataSource": "Datakällan får inte vara tom eller vara ogiltig JSON",
  "error.couldNotExtendProspect": "Kunde inte förlänga annonsen, var god försök igen senare.",
  "error.campaignSelectionNotSameGroupClient": "Alla valda kampanjer har inte samma grupp/kund",
  "error.couldNotCreateCollectionReport": "Kunde inte skapa samlingsrapport",
  "error.editLogo": `Kunde inte uppdatera logga, kontakta IT!`,
  "error.couldNotOrderAd": `Kunde inte beställa annons`,
  "error.canNotChangeGroup": "Kan inte ändra grupp",
  "error.budgetOrSubscription": "Kampanjer kan inte skapas utan budget eller prenumeration",
  "error.languageCode": "Ange en giltig språkkod, ex en-au eller sv",
  "error.currencyCode": "Ange en giltig valutakod , ex SEK eller USD",
  "error.noWhitespace": "Värdet får inte innehålla mellanslag",
  "error.uppercase": "Värdet måste vara i versaler",
  "error.lowercase": "Värdet måste vara i gemener",
  "error.clientNameUnique": "Namnet måste vara unikt, kunden finns redan i samma grupp!",
  "error.groupNameUnique": "Namnet måste vara unikt, gruppen finns redan på samma partner!",
  "error.partnerNameUnique": "Namnet måste vara unikt, partnern finns redan!",

  "error.nameUnique": "Namnet måste vara unikt!",
  "error.minTwo": "Värdet måste vara minst 2 tecken långt",
  "error.keyRequired": "Nyckeln får inte vara tom",
  "error.keyIsDuplicated": "Nyckeln är inte unik",
  "error.login": "Ogiltiga inloggningsuppgifter",
  "error.emailUnique": "En användare med den E-posten finns redan.",
  "error.tenantManager": "Kan inte skapa en tenant manager utan en tenant",
  "error.rolePassword": "Kan inte skapa en admin eller tenant manager utan lösenord",
  "error.valueUndefined": "{value} undefined",
  "error.url": "Fältet måste vara en giltig URL",
  "error.rootLink": "Root måste ha ett link fält",
  "error.idsUnique": "Idn måste vara unika",
  "error.oneItem": "Ett objekt är minimum",
  "error.deliveryGuarantee": "Leveransgarantin måste vara ett nummer och mellan 1 och 100",
  "error.channelDistribution": "Kanalfördelningen måste vara totalt 100%",
  "error.idsMaxItems": "För många ids, max 30",
  "error.minDaysToGoLive": "Datum måste vara minst 7 dagar från idag",
  "error.campaignType": "Välj ett kampnjtyps alternativ",

  "error.errorMessageMoreMinute": "Minuterna måste vara större än 0",
  "error.errorMessageLessMinute": "Minuterna får vara högst 59",
  "error.errorMessageMoreHours": "Timmar måste vara större än 0",
  "error.errorMessageLessHours": "Timmarna får vara högst 23",

  "success.collectionReport": "Samlingsrapport skapad och skickad till tech@spiderads.io",
  "success.createDataSource": `Datakällan skapad!`,
  "success.editDataSource": `Datakällan uppdaterad!`,
  "success.editThemeColor": `Temafärg uppdaterad!`,
  "success.editLogo": `Logga uppdaterad!`,
  "success.userCreated": `Användare skapad!`,
  "success.editRole": `Roll uppdaterad!`,
  "success.editMobileNumber": `Mobilnummer uppdaterad!`,

  "title.applyMarginSetting": "Applicera MargingWRTSplash",

  "hint.usedBy": "Används av {campaigns}",
  "hint.notInUse": "Används ej",
  "hint.channelProgress": `Totalt: {impressions} Bokat: {bookedImpressions} Levererat: {deliveredImpressions} Fullständighet: {completion}%`,
  "hint.addRecipient": "Lägg till mottagare genom att ange en giltig E-post",
  "hint.addCategory": "Ange kategori, lämna fältet tomt för ingen kategori",
  "hint.colorPicker": "Välj färg från palett eller klistra in hex färgkod",
  "hint.warning.display": "Säkerställ vald annonsmall, är konfigurerad för bidTheatre",
  "hint.massSelection": "Klistra in {value} kommaseparerad eller med radbrytning",
  "hint.allCampaignsSceduledReport": "Lämna tom för alla kampanjer på kunden",
  "hint.fillAllFields": "Du måste fylla i alla fälten för att kunna lägga till en kontakt",
  "hint.addFilledContact": 'Klicka på knappen "Lägg till kontakt" för att lägga till kontakten.',

  "tooltip.campaignSelection": "Kampanjer du valt i listan",
  "tooltip.pickTemplateMassUpdate": "Välj den annonsmall du vill att valda kampanjer ska uppdateras till",
  "tooltip.overrideLabel":
    "Om du önskar överskrida default referens titlen: Referens (ReferensID, Kostnadsställe och/eller Referensperson)",
  "tooltip.overridePlaceholder":
    "Om du önskar överskrida default referens platshållaren: Referens (ReferensID, Kostnadsställe och/eller Referensperson)",
  "tooltip.prefilledField":
    "Om du önskar överskrida default referens förifyllt fält: Referens (ReferensID, Kostnadsställe och/eller Referensperson)",
  "tooltip.dataSourceName": "Namnet på datakällan",
  "tooltip.inheritDataSource": "Datakällan du önskar ärva inställningar ifrån",
  "tooltip.inheritFilters": "Om du önskar kombinera eller återställa filtrena",
  "tooltip.variables": "Variabler du önskar använda ex: url",
  "tooltip.url": "Skriv in url:en till hemsidan du önskar hämta metadata ifrån",
  "tooltip.headers": "Om du önskar lägga till request headers, ex: ':scheme: https'",
  "tooltip.userAgent":
    "Om hemsidan inte tillåter okända user agents, ex: Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)",
  "tooltip.formData": "Om du önskar lägga till form data",
  "tooltip.numberOfResults": "Om du vill begränsa antalet resultat",
  "tooltip.rootData": "För delade annonser lägg title och description i root",
  "tooltip.itemsData": "För inviduella annonser, lägg title och description i items",
  "tooltip.container": "Vilken html tag eller Json nyckel du vill datakällan ska börja läsa från ex: $.items[*]",
  "tooltip.automationCreated":
    "När man skapat en automatisering kommer den gälla alla kommande prospekt, gamla prospekt berörs ej",
  "tooltip.defaultBudgetAmount": "Standardvärde budget belopp på automatiska ordrar, kan överskridas i datakällan",
  "tooltip.defaultDuration": "Standardvärde kampanjlängd (dagar) på automatiska ordrar, kan överskridas i datakällan",
  "tooltip.defaultGeo": "Standardvärde geo på automatiska ordrar, kan överskridas i datakällan",
  "tooltip.defaultRadius": "Standardvärde geo på automatiska ordrar, är 3km",
  "tooltip.fieldDescription": "Fyll i fältet {value}",
  "tooltip.textAboveAdSoMe": "Fyll i om det skall vara en statisk text ovanför annonsbild",
  "tooltip.titelTextDisplay": "Fyll i om det skall vara en statisk titeltext",
  "tooltip.maxProducts": "En karusell är max 10 objekt",

  "tooltip.sorting": "Om du önskar att spindeln sorterar resultaten efter stigande eller fallande ordning.",
  "tooltip.totalClicksValue": "Totalt antal klick: {value}",
  "tooltip.allowedFontFamily": "Endast ttf/woff fonter tillåtna",
  "tooltip.image": "Välj en bild som värde",
  "tooltip.reasonedAction": "Anledning: {reason}, Datum: {timestamp}",

  "title.lockGeoTargets": "Lås Geo Target",
  "title.automaticReport": "Automatisk resultatrapport per e-post",
  "title.companyFacebookPage": "Facebook-sida",
  "title.newAdImges": "Bilder till annons (Vid endast 1 st bild = Singelannons, och minst 2 st bilder Karusellannons)",
  "title.orderRecived": "Beställningen är mottagen",
  "title.resultReport": "Resultatrapport",
  "title.numAdShow": "Hur många gånger har annonsen visats",
  "title.numAdClickSee": "Hur många som såg annonsen klickade på den",
  "title.numAdClick": "Hur många har klickat på annonsen",
  "title.fb&bt": "Facebook {fb}% - Bidtheatre {bt}%",
  "title.channelShare": "kanalfördelning",
  "title.useCustomStaticCreatives": "Använd anpassade statiska annonsmaterial",
  "title.createCampaign": "Skapa kampanj",
  "title.cpmValue": "Cpm {value}",
  "title.campaignCategory": "Kampanjens kategorisering",
  "title.campaignDefaults": "Kampanj standardvärden",
  "title.clients": "Kunder",
  "title.latestProspect": "Senaste Prospekt",
  "title.latestOrder": "Senaste Order",
  "title.numberOfProspect": "Antal Prospekt",
  "title.configStaticData": "Konfiguera statisk datakälla",
  "title.createAdvancedDataSource": "Skapa avancerad datakälla",
  "title.createStaticDataSource": "Skapa statisk datakälla",
  "title.dataSources": "Datakällor",
  "title.notes": "Anteckningar",
  "title.templates": "Annonsmallar",
  "title.archive": "Arkivera",
  "title.viewInBaka": "Se i Baka",
  "title.campaigns": "Kampanjer",
  "title.settings": "Inställningar",
  "title.userDrafts": "Användarutkast",
  "title.pdfLibrary":"PDF bibliotek",
  "title.videoTemplates": "Videomallar",
  "title.videoPlacements": "Video Placering",
  "title.groups": "Grupper",
  "title.orders": "Ordrar",
  "title.createNewCampaign": "Skapa ny kampanj",
  "title.createBudgetPeriod": "Skapa budgetperiod",
  "title.budgetPeriods": "Budgetperioder",
  "title.clientDashboardCampaigns": "Översikt/Kampanjer",
  "title.value": "Värde",
  "title.clientDashboard": "Kundöversikt",
  "title.images": "Bilder",
  "title.uploadImages": "Ladda upp bilder",
  "title.schedule": "Schema",
  "title.recipients": "Mottagarna",
  "title.specifiedEmail": "Angiven e-postadress",
  "title.recipient": "Mottagare",
  "title.scheduledReport": "Schemalagd rapport",
  "title.scheduledReports": "Schemalagda rapporter",
  "title.specifiedEmailadress": "Skriv in e-mail",
  "title.sendOrderEmail": "Skicka beställningsmail till specifik e-post",
  "title.allCampaigns": "Samtliga kampanjer",
  "title.previewProspect": "Förhandsgranska prospekt",
  "title.canAddLinkedInBudget": "Kan lägga till LinkedIn budget",
  "title.canAddCampaignManagers": "Kan lägga till kampanjföreståndare",
  "title.hasMassSelection": "Har massurval bilder",
  "title.hasMassZipCode": "Har postkodsfält",
  "title.mediaLibararyCategory": "Kategori för mediabibliotek",
  "title.prefix": "Kampanjnamns prefix",
  "title.suffix": "Kampanjnamns suffix",
  "title.pricePackage.small": "Prispaket (Small)",
  "title.pricePackage.medium": "Prispaket (Medium)",
  "title.pricePackage.large": "Prispaket (Large)",
  "title.maxAmount": "Maxbelopp",
  "title.pricePackage.single": "Prispaket (Single)",
  "public.costCenterreferencefield": "Kostnadsställe/referensfält",
  "title.customfieldType": "Kundanpassatfält typ",
  "title.filtering": "Filtering",
  "title.customField": "Skräddarsytt fält",
  "title.sorting": "Sortering",
  "title.prospectType": "Typ av prospekt",
  "title.staticDataSource": "Statisk datakälla",
  "title.dynamicDataSource": "Dynamisk datakälla",
  "title.canUpload": "Uppladningsinställningar",
  "title.endDateSetting": "Slutdatum inställning",
  "title.preview": "Förhandsvisning",
  "title.automationsSettings": "Automatiserings inställningar",
  "title.defaultBudgetAmount": "Standardvärde budget belopp",
  "title.defaultDuration": "Standardvärde kampanjlängd (dagar)",
  "title.defaultGeo": "Standardvärde geo",
  "title.defaultRadius": "Standardvärde radius",
  "title.addFilter": "Lägg till filter",
  "title.recipientFilter": "Mottagarfiltrering",
  "title.addAutomation": "Lägg till automation",
  "title.trigger": "Trigger",
  "title.addTrigger": "Lägg till trigger",
  "title.recipientGroups": "Mottagargrupper",
  "title.fallbackRecipient": "Använd som reservmottagare endast om ingen mottagare hittas på prospektet",
  "title.addRecipient": "Lägg till mottagare",
  "title.addRecipientGroup": "Lägg till mottagargrupp",
  "title.addContact": "Lägg till kontakt",
  "title.addTargeting": "Lägg till annonsinriktning",
  "title.uploadTemplate": "Ladda upp annonsmall",
  "title.removeTargeting": "Ta bort annonsinriktning",
  "title.removeRecipientGroup": "Ta bort mottagargrupp",
  "title.removeAutomation": "Ta bort automation",
  "title.pricePackageCurrencyCode": "Prispaket i {value}",
  "title.dataSourceName": "Datakälla namn",
  "title.providers": "Leverantörer",
  "title.evaluated": "Utvärdering",
  "title.evaluate": "Utvärdera",
  "title.result": "Resultat",
  "title.output": "Utdata",
  "title.managers": "Föreståndare",
  "title.manager": "Föreståndare",
  "title.orderNumber": "Beställningsnummer",
  "title.prospect": "Prospekt",
  "title.amount": "Belopp",
  "title.allowCallAds": "Tillåt ringannonser",
  "title.massUpdateCampaign": "Uppdatera template för valda kampanjer",
  "title.addNote": "Lägg till anteckning",
  "title.campaignSelection": "Valda kampanjer",
  "title.SelectedValue": "Valda {value}",
  "title.campaignSelectionValue": "{value} kampanjer valda",
  "title.dataSourceSelectionValue": "{value} datakällor valda",
  "title.exportToExcel": "Exportera till Excel",
  "title.createCollectionReport": "Skapa samlingsrapport",
  "title.overrideLabel": "Överskrid referens titel",
  "title.overridePlaceholder": "Överskrid referens platshållare",
  "title.prefilledField": "Förifylld kostnadsställe",
  "title.exclusions": "Exkluderingar",
  "title.addExclusions": "Lägg till exkluderingar",
  "title.spiderTemplate": "Template",
  "title.inheritSettings": "Ärv inställningar",
  "title.variables": "Variabler",
  "title.addVariable": "Lägg till variabel",
  "title.container": "Behållare",
  "title.userAgent": "Mjukvaruagent",
  "title.restrictNumberOfResults": "Begränsa antalet resultat",
  "title.sortResult": "Sortera resultatet",
  "title.numberOfResults": "Antal resultat",
  "title.headers": "Headers",
  "title.formData": "Formulärdata",
  "title.addHeader": "Lägg till header",
  "title.addFormData": "Lägg till formulärdata",
  "title.key": "Nyckel",
  "title.rootData": "Root data",
  "title.addRootData": "Lägg till root data fält",
  "title.replacements": "Ersättningar",
  "title.addReplacement": "Lägg till ersättning",
  "title.removeRootData": "Ta bort root data fält",
  "title.selector": "Selektor",
  "title.transformScript": "Transformeringsskript",
  "title.pattern": "Mönster",
  "title.replacement": "Ersättning",
  "title.recursive": "Rekursiv",
  "title.itemsData": "Items Data",
  "title.addItemData": "Lägg till item data fält",
  "title.removeItemData": "Ta bort item data fält",
  "title.addProvider": "Lägg till leverantör",
  "title.typeOfFilter": "Filter typ",
  "title.requestVerificationToken": "Request Verification Token",
  "title.requestVerificationUrl": "Request Verification Url",
  "title.requestCredentialsToken": "Request Credentials Token",
  "title.requestCredentialsToken.url": "Authentication Endpoint",
  "title.requestCredentialsToken.tokenSelector": "Token Selector",
  "title.resolveCanonical": "Utvärdera kanonisk url",
  "title.subscription": "Subscription",
  "title.isTryAndBuy": "Prova & köp",
  "title.monthlyBudget": "Månadsbudget",
  "title.sameForAllMonths": "Samma budget alla månader",
  "title.users": "Användare",
  "title.user": "Användare",
  "title.manuallyEnded": "Manuellt avslutad",
  "title.updateCampaigns": "Uppdatera kampanjer",
  "title.updateProspectSources": "Uppdatera prospektkällor",
  "title.tags": "Taggar",
  "title.locale": "Locale",
  "title.brokerageFee": "Förmedlingsavgift",
  "title.mediaCategories": "Bildkategorier",
  "title.metadata": "Metadata",
  "title.sandbox": "Sandbox",
  "title.templateName": "Ge din annonsmall ett namn",
  "title.billingType": "Faktureringssätt",
  "title.deliveryGuarantee": "Leveransgaranti",
  "title.background": "Bakgrund",
  "title.color": "Färg",
  "title.image": "Bild",
  "title.size": "Storlek",
  "title.landscapeSize": "Landskap storlek",
  "title.potraitSize": "Poträtt storlek",
  "title.positionY": " position",
  "title.dimmerColor": "Dimmer färg",
  "title.dimmerOpacity": "Dimmer opacitet",
  "title.defaults": "Standardvärden",
  "title.sampleEmail": "Exempel e-post ",
  "title.emailType": "E-post typ",
  "title.emailTemplateSettings": "E-postmallsinställningar",
  "title.editClientEmailStopSettings": "Redigera klientens e-postinställning",
  "title.editCampaignEmailStopSettings": "Redigera e-postinställning för kampanj",
  "title.headerFont": "Rubrik font",
  "title.contentFont": "Innehåll font",
  "title.supportBox": "Support box",
  "title.footer": "Footer",
  "title.emailTemplateInfo": "E-postmall info",
  "title.subject": "Ämne",
  "title.markdownContent": "Markdown innehåll",
  "title.emailTemplateType": "E-postmall typ",
  "title.metaMedia": "Meta Medier (Facebook & Instagram)",
  "title.website": "Hemsida",
  "title.appDomain": "App domän",
  "title.corsAllowedDomains": "Tillåtna ytterligare domäner",
  "title.cdnDomain": "CDN domän",
  "title.emailDomain": "Email domän",
  "title.fromEmail": "E-post avsändare",
  "title.productTitle": "Produkt titel",
  "title.productLink": "Produktlänk",
  "title.smsSender": "SMS avsändare",
  "title.supportEmail": "Support epost",
  "title.orderRecipient": "Order mottagare",
  "title.apiChannels": "Tillåtna Api-kanaler",
  "title.state": "Tillstånd",
  "title.reason": "Anledning",
  "title.dataSourceFailures": "Spindlingsfel",
  "title.profiles": "Profiler",
  "title.insightsKey": "Insights nyckel",
  "title.apiKey": "Api nyckel",
  "title.saveKey": "Spara åtkomstnyckeln, det är inte möjligt att få nyckeln igen utan att skapa en ny nyckel.",
  "title.documentation": "Dokumentation: {value}",
  "title.magicTokenUsages": "Användning av magiska token",
  "title.mediaLibrarySettings": "Mediabiblioteksinställningar",
  "title.allowPublicClientMetadata": "Tillåt offentlig klientmetadata",
  "title.insightsSampleData": "Insights provdata",
  "title.prospecting": "Prospektkälla",
  "title.integrationModule": "Integrationsmodul",
  "title.partnerApi": "Partner API",
  "title.emailSelection": "Val av e-post",
  "title.stopEmailSelection": "Välj nedan vilket automatiskt e-postmeddelande du vill stoppa",
  "title.creationSource": "Skapad från",
  "title.emails": "E-postadresser",
  "title.opened": "Öppnad",
  "title.clientLiveDate": "När ska kunden gå live",
  "title.contractPeriod": "Avtalsperiod",
  "title.url": "Url",
  "title.landingPage": "Landningssida",
  "title.textAboveAdSoMe": "Text ovanför annonsbild (SoMe)",
  "title.titelTextDisplay": "Titeltext (Display)",
  "title.agreement": "Finns det ett påskrivet avtal",
  "title.maxProducts": "Max antal produkter / objekt i order",
  "title.mailRecipient": "Mail mottagare",
  "title.mailSettings": "Mail inställningar",
  "title.mailDay": "Vilken dag ska mejlet skickas ut",
  "title.mailTime": "Vilken tid ska mejlet skickas ut",
  "title.customerAndBillingInfo": "Kund & Faktureringsuppgifter",
  "title.orgNumber": "Organisationsnummer",
  "title.address": "Adress",
  "title.clientContacts": "Kundkontakter",
  "title.generalCampaignSettings": "Generella kampanjinställningar",
  "title.campaignType": "Kampanjtyp",
  "title.pricePackageSettings": "Prispakets inställningar",
  "title.verticals": "Vertikaler",
  "title.mediaDistribution": "Välj fördelning mellan medier",
  "title.clientFBPage": "Faceebooksida (Avsändare i Metaannonserna)",
  "title.costcenter": "Eventuellt kostnadsställe på faktura",
  "title.OrderTemplateSettings": "Inställningar för beställningsmall",
  "title.OrderItemsForm": "Lägg till formulär för beställningsartiklar",
  "title.ConfigureOrderTemplateItem": "Konfigurera OrderTemplate-objekt",
  "title.campaignData": "Kampanjdata",
  "title.locationAndCoordinates": "Plats och koordinater",
  "title.referenceSettings": "Referensinställningar",
  "title.referenceSettingsLabel": "Fältnamn",
  "title.referenceSettingsplaceholder": "Platshållare",
  "title.ReferenceSettingvalue": "Värde",
  "title.SelectedOrderTemplate": "Välj Beställningsmall",
  "title.createOrderTemplate": "Skapa ordermall",
  "title.editOrderTemplate": "Redigera ordermall",
  "title.minAge": "Min ålder",
  "title.maxAge": "Max ålder",
  "title.adAccountId": "Annonskonto-id",
  "title.linkedInPageId": "LinkedIn sida-id",
  "title.linkedInAdAccountId": "LinkedIn annonskonto-id",
  "title.hasSinglePrice": "Enkel prissättning",
  "title.useStaticTitle": "Använd statisk titel",
  "title.language": "Språk",
  "title.orderTemplateName": "Namn på beställningsmall",
  "title.initialPrice": "Ursprungligt pris",
  "title.pricingLevels": "Prisnivåer",
  "title.templateTags": "Malltaggar",
  "title.reportReciepient": "Rapportmottagare",
  "title.imageCategories": "Bildkategorier",
  "title.campaignDataItemLandingPage": "Kampanjens målsida",
  "title.campaignDataLogoUrl": "Logotyp Länk",
  "title.campaignDataHeader": "Kampanjrubrik",
  "title.campaignDataItemDefaultTitle": "Kampanjens standardtitel",
  "title.campaignDataItemDefaultSubTitle": "Kampanjens standardunderrubrik",
  "title.campaignDataItemImageUrl": "Campaign Default ImageUrl",
  "title.OrderTemplateSettingsForm": "Inställningsformulär för beställningsmall",
  "title.ConfigureLocationItem": "Mallplats",
  "title.OrderLocationItemsForm": "Mallplats",
  "title.ConfigureCordinates": "Mallkoordinater",
  "title.campaignLocationItemName": "Kampanjens platsnamn",
  "title.campaignDataItemRadius": "Kampanjens radie",
  "title.campaignLocationLat": "Campaign Plats Lat",
  "title.campaignLocationLong": "Campaign Plats Long",
  "title.OrderCordinateItemsForm": "Beställ koordinater",
  "title.campaignName": "Kampanj namn",
  "title.phoneNumber": "Telefonnummer",
  "title.addfield": "Lägg till fält",
  "title.carouselAd": "Karusellannons",
  "title.callAd": "Ringannons",
  "title.handover": "Överlämning",
  "title.telemetry": "Telemetri",
  "title.fallbackFacebookPageId": "Alternativ Facebook-sida-id",
  "title.fallbackLinkedInPageId": "Alternativ LinkedIn-sida-id",
  "title.cpmSettings": "Cpm inställningar",
  "title.updated": "Uppdaterat",
  "title.fanout": "Fanout",
  "title.fanoutAllClients": "Välj alla tillgängliga kunder i gruppen",
  "title.addFanout": "Lägg till Fanout",
  "title.removeFanout": "Ta bort Fanout",

  "placeholder.noGroup": "Ingen grupp",
  "placeholder.noReseller": "Ingen återförsäljare",
  "placeholder.noDataSourceChoosen": "Ingen datakälla vald",
  "placeholder.noTemplateChoosen": "Ingen annonsmall vald",
  "placeholder.mobileExample": "+46723395200",
  "placeholder.chooseColor": "Välj färg",
  "placeholder.chooseCategory": "Välj kategori",
  "placeholder.noTenant": "Ingen partner",
  "placeholder.chooseLanguage": " Välj språk",
  "placeholder.noAddedContacts": "Inga kontakter är tillagda",
  "placeholder.selectClients": "Välj kunder",

  "handover.title.salesman": "Ange säljarens uppgifter",
  "handover.title.customer": "Fyll i din slutkunds uppgifter",
  "handover.title.mediaDistribution": "Inställningar & information i annonskampanjen",
  "handover.title.campaignDetails": "Inställningar & information i annonskampanjen",
  "handover.navigation.salesman": "Säljarinformation",
  "handover.navigation.customer": "Kunduppgifter",
  "handover.navigation.mediaDistribution": "Mediahantering",
  "handover.navigation.campaignDetails": "Kampanjinställningar",

  "handover.label.name": "Ditt namn",
  "handover.label.email": "Din E-post",
  "handover.label.companyName": "Företagets namn",
  "handover.label.postTown": "Postort",

  "handover.helper.fieldDescription": "Fyll i {value}",
  "handover.helper.clientFieldDescription": "Fyll i slutkundens {value}",
  "handover.helper.name": "den ansvarige säljarens namn.",
  "handover.helper.email": "den ansvarige säljarens E-post.",
  "handover.helper.companyName": "namnet på företaget du jobbar för.",
  "handover.helper.emailForBilling": "för fakturering.",
  "handover.helper.emailForReport": "Ansvarig persons e-post, om sådan finns. Flera e-postadresser kan anges",

  "handover.success": "Tack för din överlämning!",
  "handover.success.description":
    "På den mejladress du angivit vid överlämningen kommer du få en sammanfattning av överlämningen, så håll utkik i inkorgen.",

  "telemetry.delivery": "Leverans de senaste 30 dagarna",

  "options.approvedAdTemplate": "Godkänd annonsmall finns",
  "options.deliveryOrders": "Leverans beställer",

  "campaignType.alwaysOn": "Always On",
  "campaignType.semiAutomatic": "Semi Automatisk",
  "campaignType.fullyAutomatic": "Helautomatisk Engångs Annonsering",
  "campaignType.weeklyBoost": "Vecko Boost",
  "campaignCategory.recruitment": "Rekrytering",
  "campaignCategory.carDealers": "Personbilar & Specialfordon",
  "campaignCategory.realEstateAgents": "Fastighetsmäklare",
  "campaignCategory.propertyOwners": "Fastighetsägare & lokaluthyrare",
  "campaignCategory.pressReleases": "Pressreleaser & Nyheter",
  "campaignCategory.retailFewStores": "Retail (Vid bara 1-5 stycken butiker samma kedja)",
  "campaignCategory.retailManyStores": "Retail (Vid bara 6 eller flera stycken butiker samma kedja)",

  "sliderLabel.fb&bt0": "Facebook {value}%",
  "sliderLabel.fb&bt1": "Bidtheatre {value}%",
  "sliderLabel.facebook": "Facebook {value}%",
  "sliderLabel.bidTheatre": "Bidtheatre {value}%",

  "documentTitle.resultReport": "Resultatrapport för {campaignName}",

  "filterType.equals": "Är lika med",
  "filterType.contains": "Innehåller",
  "filterType.notContains": "Innehåller inte",
  "filterType.lessThan": "Mindre än",
  "filterType.greaterThan": "Större än",
  "filterType.hasValue": "Har värde",
  "filterType.noValue": "Har inget värde",
  "filterType.regex": "Regex matchning",
  "filterType.regexInverted": "Negativt Regex",
  "filterType.in": "Finns i",
  "filterType.notIn": "Finns inte i",

  "filterLogicType.or": "Eller",
  "filterLogicType.and": "Och",
  "filterLogicType.or_not": "Eller inte",
  "filterLogicType.and_not": "Och inte",

  "scheduledReportType.weekly": "Veckovis",
  "scheduledReportType.monthly": "Månadsvis",
  "scheduledReportType.quaterly": "Kvartalsvis",
  "scheduledReportType.yearly": "Årsvis",

  "referenceType.none": "Visa ej",
  "referenceType.optional": "Ej kravbelagt",
  "referenceType.required": "Kravbelagt",
  "referenceType.locked": "Låst fält",

  "prospectType.object": "Objekt",
  "prospectType.job": "Ledigt jobb",
  "prospectType.offer": "Erbjudande",
  "prospectType.realEstate": "Fastighet",

  "landingPageSetting.uneditable": "Ej redigerbar",
  "landingPageSetting.same": "Samma",
  "landingPageSetting.unique": "Unik",

  "referenceSettings.none": "Ingen",
  "referenceSettings.optional": "Frivillig",
  "referenceSettings.required": "Nödvändig",
  "referenceSettings.locked": "Låst",

  "phoneNumberSettings.disabled": "Inaktiverad",
  "phoneNumberSettings.unique": "Unik",
  "phoneNumberSettings.same": "Samma för alla",

  "canUploadSetting.no": "Kan inte ladda upp bild",
  "canUploadSetting.toProspect": "Kan endast ladda upp till prospektet",
  "canUploadSetting.toClient": "Kan ladda upp till mediabiblioteket på kund",
  "canUploadSetting.toHQ": "Kan ladda upp till mediebiblioteket på HQ",

  "channelCampaignState.active": "Aktiv",
  "channelCampaignState.ended": "Avslutad",
  "channelCampaignState.scheduled": "Schemalagd",
  "channelCampaignState.warning": "Varning",
  "channelCampaignState.broken": "Trasig",

  "enum.campaignStateType.archived": "Arkiverad",
  "enum.campaignStateType.paused": "Pausad",
  "enum.campaignStateType.ended": "Avslutad",
  "enum.campaignStateType.scheduled": "Schemalagd",
  "enum.campaignStateType.live": "Aktiv",

  "enum.role.admin": "Admin",
  "enum.role.user": "User",
  "enum.role.prospectUser": "ProspectUser",
  "enum.role.tenantManager": "TenantManager",
  "enum.role.apiKeyUser": "ApiKeyUser",

  "enum.userStatus.active": "Aktiv",
  "enum.userStatus.inactive": "Inaktiv",

  "enum.status.notOpened": "Inte öppnad",
  "enum.status.opened": "Öppnad",
  "enum.status.ordered": "Beställd",

  "enum.endDateSetting.none": "Ingen",
  "enum.endDateSetting.monday": "Måndag",
  "enum.endDateSetting.tuesday": "Tisdag",
  "enum.endDateSetting.wednesday": "Onsdag",
  "enum.endDateSetting.thursday": "Torsdag",
  "enum.endDateSetting.friday": "Fredag",
  "enum.endDateSetting.saturday": "Lördag",
  "enum.endDateSetting.sunday": "Söndag",

  "enum.adAccountStrategy.group": "Grupp",
  "enum.adAccountStrategy.client": "Kund",
  "enum.adAccountStrategy.tenant": "Partner",

  "enum.groupMediaLibrarySettings.everythingAvailable": "Allt Tillgängligt",
  "enum.groupMediaLibrarySettings.onlyClientOrHqWhenAvailable": "Endast klient eller Hq när tillgängligt",

  "enum.emailType.orderCampaignCreate": "Kampanj skapad",
  "enum.emailType.orderCampaignExtend": "Kampanj förlängd",
  "enum.emailType.campaignLive": "Kampanj startad",
  "enum.emailType.campaignEnd": "Kampanj avslutad",
  "enum.creationSourceType.manual": "Manuell",
  "enum.creationSourceType.prospecting": "Prospektkälla",
  "enum.creationSourceType.prospectingAutomation": "Automatiserad Prospektkälla",
  "enum.creationSourceType.integration": "Integration",
  "enum.creationSourceType.api": "Partner API",
  "enum.creationSourceType.draftTemplate": "Utkast till mall",
  "enum.creationSourceType.batch": "Batch",

  "enum.profileAccess.none": "Ingen",
  "enum.profileAccess.pending": "Väntande",
  "enum.profileAccess.approved": "Godkänd",
  "enum.profileAccess.error": "Fel",

  "adsLastUpdated.all": "Alla",
  "adsLastUpdated.oneDay": "> 1 dag",
  "adsLastUpdated.twoDays": "> 2 dagar",
  "adsLastUpdated.threeDays": "> 3 dagar",
  "adsLastUpdated.fourDays": "> 4 dagar",
  "adsLastUpdated.fiveDays": "> 5 dagar",
  "adsLastUpdated.sixDays": "> 6 dagar",
  "adsLastUpdated.oneWeek": "> 1 vecka",
  "adsLastUpdated.twoWeeks": "> 2 veckor",
  "adsLastUpdated.threeWeeks": "> 3 veckor",

  "crawlSpeed.00:15:00": "15 Minuter",
  "crawlSpeed.00:30:00": "30 Minuter",
  "crawlSpeed.00:45:00": "45 Minuter",
  "crawlSpeed.01:00:00": "1 Timme",
  "crawlSpeed.03:00:00": "3 Timmar",
  "crawlSpeed.06:00:00": "6 Timmar",
  "crawlSpeed.12:00:00": "12 Timmar",
  "crawlSpeed.1.00:00:00": "1 Dag",
  "crawlSpeed.3.00:00:00": "3 Dagar",
  "crawlSpeed.7.00:00:00": "7 Dagar",

  "time.seconds": " Sekunder",
  "time.minutes": " Minuter",
  "time.hours": " Timmar",
  "time.days": " Dagar",

  "weekdays.monday": "Måndag",
  "weekdays.tuesday": "Tisdag",
  "weekdays.wednesday": "Onsdag",
  "weekdays.thursday": "Torsdag",
  "weekdays.friday": "Fredag",
  "weekdays.saturday": "Lördag",
  "weekdays.sunday": "Söndag",

  "dataSourceProviderTemplateFilter.combine": "Kombinera",
  "dataSourceProviderTemplateFilter.reset": "Återställ",

  "recipientMap.title": "Mappningar för mottagare",
  "recipientMap.addRecipientMap": "Lägg till mappning",
  "recipientMap.override": "Överskrivning",
  "recipientMap.reference.error": "Referens måste vara ifylld.",
  "recipientMap.override.error": "Överskrivningen måste vara ifylld, och giltig om e-postadress.",

  "enum.ascending": "Stigande",
  "enum.descending": "Fallande",

  "billingType.unspecified": "Ospecifierad",
  "billingType.monthly": "Månadsvis i förskott",
  "billingType.monthlyArrears": "Månadsvis i efterskott",
  "billingType.quarterly": "Kvartalvis i förskott",
  "billingType.semiYearly": "Semi årvis i förskott",
  "billingType.yearly": "Årsvis i förskott",
  "billingType.onDemand": "Vid beställning i webbshop",
  "billingType.external": "Extern",
  "cancelReason.duplicate": "Dubblett",
  "cancelReason.regretted": "Ångrad",
  "cancelReason.incorrect": "Felaktig",

  "videoFormats.square": "Square",
  "videoFormats.portrait9x16": "Portrait9x16",
  "videoFormats.landscape16x9": "Landscape16x9",
  "videoFormats.vertical": "Vertical",
  "videoFormats.all": "Alla",
  "videoFormats.none": "Ingen",

  "sampleEmailType.prospectFound": "Prospekt funnet",
  "sampleEmailType.prospectOrdered": "Order lagd",
  "sampleEmailType.prospectExtended": "Order förlängd",
  "sampleEmailType.prospectCampaignLive": "Kampanj startad",
  "sampleEmailType.prospectCampaignEnded": "Kampanj avslutad",
  "sampleEmailType.scheduledReport": "Schemalagd rapport",
  "sampleEmailType.sendDraft": "GA utkast",

  "adCategory.IAB1": "Konst & Underhållning",
  "adCategory.IAB10": "Hem & trädgård",
  "adCategory.IAB10-1": "Trädgårdsutrustning",
  "adCategory.IAB10-2": "Underhållning",
  "adCategory.IAB10-3": "Miljösäkerhet",
  "adCategory.IAB10-4": "Trädgårdsarbete",
  "adCategory.IAB10-5": "Hemmafixare",
  "adCategory.IAB10-6": "Hemmabio",
  "adCategory.IAB10-7": "Heminredning",
  "adCategory.IAB10-8": "Landskapsarkitektur",
  "adCategory.IAB10-9": "Ombyggnad & Konstruktion",
  "adCategory.IAB11": "Statligt, Lag & Politik",
  "adCategory.IAB1-1": "Böcker & Litteratur",
  "adCategory.IAB11-2": "Rättsliga problem",
  "adCategory.IAB11-4": "Politik (S)",
  "adCategory.IAB12": "Nyheter",
  "adCategory.IAB1-2": "Kändisar fans/skvaller",
  "adCategory.IAB12-1": "Internationella nyheter",
  "adCategory.IAB12-2": "Inrikesnyheter",
  "adCategory.IAB12-3": "Lokala nyheter",
  "adCategory.IAB1-3": "Konst",
  "adCategory.IAB13": "Privatekonomi (S)",
  "adCategory.IAB13-1": "Nyföretagande, investering (S)",
  "adCategory.IAB13-10": "Pensionsplanering (S)",
  "adCategory.IAB13-11": "Aktier (S)",
  "adCategory.IAB13-12": "Skatteplanering (S)",
  "adCategory.IAB13-2": "Kredit/skulder & lån (S)",
  "adCategory.IAB13-3": "Finansiella nyheter (S)",
  "adCategory.IAB13-4": "Ekonomisk planering (S)",
  "adCategory.IAB13-5": "Hedgefond (S)",
  "adCategory.IAB13-6": "Försäkring (S)",
  "adCategory.IAB13-7": "Investering (S)",
  "adCategory.IAB13-8": "Placeringsfond (S)",
  "adCategory.IAB13-9": "Optioner (S)",
  "adCategory.IAB14": "Socialt",
  "adCategory.IAB1-4": "Humor",
  "adCategory.IAB14-1": "Dejting",
  "adCategory.IAB14-2": "Skilsmässorådgivning",
  "adCategory.IAB14-4": "Giftermål",
  "adCategory.IAB14-5": "Pensionärsliv",
  "adCategory.IAB14-6": "Tonåring",
  "adCategory.IAB14-7": "Bröllop",
  "adCategory.IAB15": "Vetenskap",
  "adCategory.IAB1-5": "Filmer",
  "adCategory.IAB15-10": "Väder",
  "adCategory.IAB15-2": "Biologi",
  "adCategory.IAB15-3": "Kemi",
  "adCategory.IAB15-4": "Geologi",
  "adCategory.IAB15-6": "Fysik",
  "adCategory.IAB15-7": "Rymd/astronomi",
  "adCategory.IAB15-8": "Geografi",
  "adCategory.IAB15-9": "Botanik",
  "adCategory.IAB16": "Husdjur",
  "adCategory.IAB1-6": "Musik",
  "adCategory.IAB16-1": "Akvarium",
  "adCategory.IAB16-2": "Fåglar",
  "adCategory.IAB16-3": "Katter",
  "adCategory.IAB16-4": "Hundar",
  "adCategory.IAB16-5": "Stora djur",
  "adCategory.IAB16-6": "Reptiler",
  "adCategory.IAB16-7": "Vetrinärmedicin",
  "adCategory.IAB17": "Sport",
  "adCategory.IAB1-7": "TV",
  "adCategory.IAB17-1": "Bilsport",
  "adCategory.IAB17-10": "Konståkning",
  "adCategory.IAB17-11": "Flugfiske",
  "adCategory.IAB17-12": "Amerikansk fotboll",
  "adCategory.IAB17-13": "Sötvattensfiske",
  "adCategory.IAB17-14": "Vilt & Fiske",
  "adCategory.IAB17-15": "Golf",
  "adCategory.IAB17-16": "Hästkapplöpning",
  "adCategory.IAB17-17": "Hästar",
  "adCategory.IAB17-18": "Jakt/skytte",
  "adCategory.IAB17-19": "Inlineåkning",
  "adCategory.IAB17-2": "Baseball",
  "adCategory.IAB17-20": "Kampsport",
  "adCategory.IAB17-21": "Mountainbiking",
  "adCategory.IAB17-23": "Olympiska spelen",
  "adCategory.IAB17-24": "Paintball",
  "adCategory.IAB17-25": "Motor & Motorcyklar",
  "adCategory.IAB17-26": "Basket",
  "adCategory.IAB17-27": "Ishockey",
  "adCategory.IAB17-28": "Rodeo",
  "adCategory.IAB17-29": "Rugby",
  "adCategory.IAB17-3": "Cykling",
  "adCategory.IAB17-30": "Löpning/joggning",
  "adCategory.IAB17-31": "Segling",
  "adCategory.IAB17-32": "Saltvattensfiske",
  "adCategory.IAB17-33": "Dykning",
  "adCategory.IAB17-34": "Skateboarding",
  "adCategory.IAB17-35": "Skidåkning",
  "adCategory.IAB17-36": "Snowboardsåkning",
  "adCategory.IAB17-37": "Surfing/bodysurfing",
  "adCategory.IAB17-38": "Simmning",
  "adCategory.IAB17-39": "Bordtennis",
  "adCategory.IAB17-4": "Bodybuilding",
  "adCategory.IAB17-40": "Tennis",
  "adCategory.IAB17-41": "Vollyboll",
  "adCategory.IAB17-42": "Gång",
  "adCategory.IAB17-43": "Vattenskidor/Wakeboard",
  "adCategory.IAB17-44": "Fotboll",
  "adCategory.IAB17-5": "Boxning",
  "adCategory.IAB17-6": "Kanot/kajak",
  "adCategory.IAB17-7": "Cheerleading",
  "adCategory.IAB17-8": "Klättring",
  "adCategory.IAB17-9": "Kricket",
  "adCategory.IAB18": "Mode & Stil",
  "adCategory.IAB18-1": "Skönhet",
  "adCategory.IAB18-2": "Kroppsutsmyckning",
  "adCategory.IAB18-3": "Mode",
  "adCategory.IAB18-4": "Smycken",
  "adCategory.IAB18-5": "Kläder",
  "adCategory.IAB18-6": "Accessoarer",
  "adCategory.IAB19": "Teknik & Datakunskap",
  "adCategory.IAB19-5": "Kameror & Videokameror",
  "adCategory.IAB19-6": "Mobiltelefoner",
  "adCategory.IAB2": "Bilar/Fordon (S)",
  "adCategory.IAB20": "Resor",
  "adCategory.IAB20-1": "Äventyrsresor",
  "adCategory.IAB20-10": "Canada",
  "adCategory.IAB20-11": "Västindien",
  "adCategory.IAB20-12": "Kryssningar",
  "adCategory.IAB20-13": "Östeuropa",
  "adCategory.IAB20-14": "Europa",
  "adCategory.IAB20-15": "Frankrike",
  "adCategory.IAB20-16": "Grekland",
  "adCategory.IAB20-17": "Smekmånader/parresor",
  "adCategory.IAB20-18": "Hotell",
  "adCategory.IAB20-19": "Italien",
  "adCategory.IAB20-2": "Afrika",
  "adCategory.IAB20-20": "Japan",
  "adCategory.IAB20-21": "Mexico & Centralamerika",
  "adCategory.IAB20-22": "Naturreservat",
  "adCategory.IAB20-23": "Sydamerika",
  "adCategory.IAB20-24": "Spa",
  "adCategory.IAB20-25": "Nöjesparker",
  "adCategory.IAB20-26": "Resa med barn",
  "adCategory.IAB20-27": "England",
  "adCategory.IAB20-3": "Flyg",
  "adCategory.IAB20-4": "Australien & Nya Zealand",
  "adCategory.IAB20-5": "Pensionat",
  "adCategory.IAB20-6": "Budgetresor",
  "adCategory.IAB20-7": "Affärsresor",
  "adCategory.IAB20-8": "USA resa",
  "adCategory.IAB20-9": "Camping",
  "adCategory.IAB21": "Fastigheter (S)",
  "adCategory.IAB21-1": "Lägenheter (S)",
  "adCategory.IAB21-2": "Arkitekter (S)",
  "adCategory.IAB21-3": "Köpa/sälj hem (S)",
  "adCategory.IAB2-1": "Bildelar",
  "adCategory.IAB2-10": "Elbil",
  "adCategory.IAB2-11": "Kombi",
  "adCategory.IAB2-12": "Hybridbil",
  "adCategory.IAB2-13": "Lyxbil",
  "adCategory.IAB2-14": "MiniVan",
  "adCategory.IAB2-15": "Motorcyklar",
  "adCategory.IAB2-16": "Off-Road fordon",
  "adCategory.IAB2-17": "Sportbilar",
  "adCategory.IAB2-18": "Pickuper",
  "adCategory.IAB2-19": "Bilassistans",
  "adCategory.IAB22": "Shopping",
  "adCategory.IAB2-2": "Bilreparationer",
  "adCategory.IAB2-20": "Sedan",
  "adCategory.IAB2-21": "Lastbilar & tillbehör",
  "adCategory.IAB22-1": "Tävlingar & gratis",
  "adCategory.IAB2-22": "Veteranbilar",
  "adCategory.IAB22-2": "Kuponger",
  "adCategory.IAB2-23": "Vagn",
  "adCategory.IAB22-4": "Shoppingjämförelse",
  "adCategory.IAB23": "Religion & andlighet",
  "adCategory.IAB2-3": "Köpa/Sälja bilar (S)",
  "adCategory.IAB23-1": "Alternativa religioner",
  "adCategory.IAB23-10": "Hednisk/Wiccan",
  "adCategory.IAB23-2": "Ateism/agnostiker",
  "adCategory.IAB23-3": "Buddism",
  "adCategory.IAB23-4": "Katolicism",
  "adCategory.IAB23-5": "Kristendom",
  "adCategory.IAB23-6": "Hinduism",
  "adCategory.IAB23-7": "Islam",
  "adCategory.IAB23-8": "Judendom",
  "adCategory.IAB2-4": "Bilkultur",
  "adCategory.IAB2-5": "Begagnade bilar",
  "adCategory.IAB2-6": "Cabriolet",
  "adCategory.IAB2-7": "Kupé",
  "adCategory.IAB2-8": "Crossover bil",
  "adCategory.IAB2-9": "Diesel",
  "adCategory.IAB3": "Företag & Industri",
  "adCategory.IAB3-1": "Annonsering",
  "adCategory.IAB3-10": "Logistik",
  "adCategory.IAB3-11": "Marknadsföring",
  "adCategory.IAB3-12": "Metaller",
  "adCategory.IAB3-2": "Jordbruk",
  "adCategory.IAB3-3": "Bioteknik/Biomedicin",
  "adCategory.IAB3-4": "Industriell mjukvara",
  "adCategory.IAB3-5": "Konstruktion",
  "adCategory.IAB3-6": "Skogsbruk",
  "adCategory.IAB3-7": "Statlig verksamhet",
  "adCategory.IAB3-8": "Gröna lösningar",
  "adCategory.IAB3-9": "Bemanning/HR",
  "adCategory.IAB4": "Karriär (S)",
  "adCategory.IAB4-1": "Karriärsplanering (S)",
  "adCategory.IAB4-10": "Militär (S)",
  "adCategory.IAB4-11": "Karriärsrådgivning (S)",
  "adCategory.IAB4-2": "Gymnasium (S)",
  "adCategory.IAB4-3": "Ekonomiskt stöd (S)",
  "adCategory.IAB4-4": "Jobbmässor (S)",
  "adCategory.IAB4-5": "Jobbsök (S)",
  "adCategory.IAB4-6": "Resumeér/CV - rådgivning (S)",
  "adCategory.IAB4-7": "Omvårdnad (S)",
  "adCategory.IAB4-8": "Stipendium (S)",
  "adCategory.IAB5": "Utbildning",
  "adCategory.IAB5-1": "Låg/mellanstadiet",
  "adCategory.IAB5-10": "Hemstudier",
  "adCategory.IAB5-11": "Läxhjälp/studietips",
  "adCategory.IAB5-12": "Låg- & mellanstadie lärare",
  "adCategory.IAB5-13": "Privatskolor",
  "adCategory.IAB5-14": "Utbildning för särskilda behov",
  "adCategory.IAB5-15": "Affärsstudier",
  "adCategory.IAB5-2": "Vuxenutbildning",
  "adCategory.IAB5-3": "Konsthistoria",
  "adCategory.IAB5-4": "Gymnasium, administration",
  "adCategory.IAB5-5": "Gymnasium, livsstil",
  "adCategory.IAB5-6": "Distansstudier",
  "adCategory.IAB5-7": "Engelska som 2a språk",
  "adCategory.IAB5-8": "Språkstudier",
  "adCategory.IAB5-9": "Forskarskola",
  "adCategory.IAB6": "Familj & Föräldrarskap",
  "adCategory.IAB6-1": "Adoption",
  "adCategory.IAB6-2": "Bebisar & spädbarn",
  "adCategory.IAB6-3": "Dagis/förskola",
  "adCategory.IAB6-4": "Internet för familjen",
  "adCategory.IAB6-5": "Låg- & mellanstadie föräldrar",
  "adCategory.IAB6-6": "Tonårsföräldrar",
  "adCategory.IAB6-7": "Graviditet",
  "adCategory.IAB6-8": "Barn med särskilda behov",
  "adCategory.IAB6-9": "Ålderdomshem",
  "adCategory.IAB7": "Hälsa & Träning",
  "adCategory.IAB7-1": "Motion",
  "adCategory.IAB7-10": "Hjärntumör",
  "adCategory.IAB7-11": "Cancer",
  "adCategory.IAB7-12": "Kolestrol",
  "adCategory.IAB7-13": "Kroniskt trötthetssyndrom",
  "adCategory.IAB7-14": "Kronisk smärta",
  "adCategory.IAB7-15": "Förkylning & Influensa",
  "adCategory.IAB7-16": "Dövhet",
  "adCategory.IAB7-17": "Tandvård",
  "adCategory.IAB7-18": "Depression",
  "adCategory.IAB7-19": "Hudvård",
  "adCategory.IAB7-2": "ADHD",
  "adCategory.IAB7-20": "Diabetes",
  "adCategory.IAB7-21": "Epelipsi",
  "adCategory.IAB7-22": "GERD/Acid Reflux",
  "adCategory.IAB7-23": "Huvudvärk/Migrän",
  "adCategory.IAB7-24": "Hjärtsjukdom",
  "adCategory.IAB7-27": "Irritabel tarm/Crohns sjukdom",
  "adCategory.IAB7-29": "Inkontinens",
  "adCategory.IAB7-3": "AIDS/HIV",
  "adCategory.IAB7-30": "Infertilitet",
  "adCategory.IAB7-31": "Hälsa - män",
  "adCategory.IAB7-32": "Näring",
  "adCategory.IAB7-33": "Ortopeder",
  "adCategory.IAB7-34": "Panik/ångest problem",
  "adCategory.IAB7-35": "Barnläkare",
  "adCategory.IAB7-36": "Sjukgymnastik",
  "adCategory.IAB7-37": "Psykolog/psykiatriker",
  "adCategory.IAB7-38": "Seniorhälsa",
  "adCategory.IAB7-4": "Allergier",
  "adCategory.IAB7-40": "Sömnproblem",
  "adCategory.IAB7-41": "Sluta röka",
  "adCategory.IAB7-42": "Missbruksproblem",
  "adCategory.IAB7-43": "Sköldkörtelsjukdom",
  "adCategory.IAB7-44": "Viktminskning",
  "adCategory.IAB7-45": "Hälsa - kvinnor",
  "adCategory.IAB7-6": "Reumatism",
  "adCategory.IAB7-7": "Astma",
  "adCategory.IAB7-8": "Autism",
  "adCategory.IAB7-9": "Bipolär sjukdom",
  "adCategory.IAB8": "Mat & dryck",
  "adCategory.IAB8-1": "Amerikanskt kök",
  "adCategory.IAB8-10": "Matallergier",
  "adCategory.IAB8-11": "Franskt kök",
  "adCategory.IAB8-12": "Hälso-/fettfri matlagning",
  "adCategory.IAB8-13": "Italienskt kök",
  "adCategory.IAB8-14": "Japanskt kök",
  "adCategory.IAB8-15": "Mexikanskt kök",
  "adCategory.IAB8-16": "Vegan",
  "adCategory.IAB8-17": "Vegetarian",
  "adCategory.IAB8-18": "Vin",
  "adCategory.IAB8-2": "Barbeque & grillning",
  "adCategory.IAB8-3": "Cajun/Creole",
  "adCategory.IAB8-4": "Kinesisk mat",
  "adCategory.IAB8-5": "Cocktails/öl",
  "adCategory.IAB8-6": "Kaffe/Te",
  "adCategory.IAB8-7": "Särskilt kök",
  "adCategory.IAB8-8": "Dessert & bakning",
  "adCategory.IAB8-9": "Äta ute",
  "adCategory.IAB9": "Hobby & Intressen",
  "adCategory.IAB9-1": "Konst/teknik",
  "adCategory.IAB9-10": "Samlande",
  "adCategory.IAB9-11": "Serier",
  "adCategory.IAB9-12": "Rita/skissa",
  "adCategory.IAB9-13": "Frilansskrivande",
  "adCategory.IAB9-14": "Släktforskning",
  "adCategory.IAB9-15": "Bli publicerad",
  "adCategory.IAB9-16": "Gitarr",
  "adCategory.IAB9-17": "Hemmastudio",
  "adCategory.IAB9-18": "Investerare & patent",
  "adCategory.IAB9-19": "Smyckestillverkning",
  "adCategory.IAB9-2": "Konst & Hantverk",
  "adCategory.IAB9-20": "Magi & Illusion",
  "adCategory.IAB9-21": "Handarbete",
  "adCategory.IAB9-22": "Måla",
  "adCategory.IAB9-23": "Fotografi",
  "adCategory.IAB9-24": "Radio",
  "adCategory.IAB9-25": "Rollspel",
  "adCategory.IAB9-26": "Sciencefiction & Fantasy",
  "adCategory.IAB9-27": "Scrapbooking",
  "adCategory.IAB9-28": "Manusförfattande",
  "adCategory.IAB9-29": "Frimärken & mynt",
  "adCategory.IAB9-3": "Pärlarbete",
  "adCategory.IAB9-30": "Video-/dataspel",
  "adCategory.IAB9-31": "Snickeri",
  "adCategory.IAB9-4": "Fågelskådning",
  "adCategory.IAB9-5": "Brädspel/pussel",
  "adCategory.IAB9-6": "Ljus & tvåltillverkning",
  "adCategory.IAB9-7": "Kortspel",
  "adCategory.IAB9-8": "Schack",
  "adCategory.IAB9-9": "Cigarrer",
}
